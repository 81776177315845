import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
} from 'reactstrap';
import * as routes from '../constants/routes';
import { getIconFromCategoryName } from '../helpers';
import { removeItemAsync, returnLoanAsync } from '../reducers';
import AddItemModal from './AddItemModal';
import BetaUsers from './BetaUsers';
import ContactUserModal from './ContactUserModal';
import Emoji from './Emoji';
import GiveAway from './GiveAway';
import OutloanModal from './OutloanModal';
import ProfileLink from './ProfileLink';

const datefns = require('date-fns');
const today = Date.now();

function getDateText(date) {
  let days = datefns.differenceInCalendarDays(date, today);
  let months = datefns.differenceInCalendarMonths(date, today);
  let years = datefns.differenceInCalendarYears(date, today);

  return days < 0
    ? 'passerat'
    : days === 0
    ? 'sista dagen'
    : days === 1
    ? days + ' dag kvar'
    : days <= 31
    ? days + ' dagar kvar'
    : months === 1
    ? '> ' + months + ' månad kvar'
    : months <= 11
    ? '> ' + months + ' månader kvar'
    : '> ' + years + ' år kvar';
}

const ItemLink = ({ id, children }) => {
  return (
    <Link
      style={{ textDecoration: 'inherit', color: 'inherit' }}
      to={{
        pathname: `${routes.ITEM}/${id}`,
        state: { modal: true },
      }}
    >
      {children}
    </Link>
  );
};

const Item = props => {
  const item = props.item;

  const owner = props.userMap[item.owner] || {};
  const borrower = props.userMap[(item.currentLoan || {}).borrowerId] || {};
  const locationName = (owner.location_name || '').split(',')[0];
  const myUserProfile = props.myUserProfile;
  const showTown = props.showTown;
  const showExtendedActions = props.showExtendedActions;
  const suggestion = props.suggestion;
  const isMine = props.isMine(item);

  return (
    <>
      <ItemLink id={item._id}>
        {item.image_id && (
          <CardImg
            top
            src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_limit,e_trim:10,w_300/v1/${item.image_id}.png`}
            alt={item.name}
            width="100%"
            height={
              item.image && item.image.height && item.image.width
                ? item.image.height / (item.image.width / 300)
                : ''
            }
          />
        )}
      </ItemLink>

      <Card
        body
        className={
          (!!item.currentLoan ? 'outloaned ' : '') + 'borrowit-card p-3'
        }
      >
        <ItemLink id={item._id}>
          {!!item.currentLoan &&
          item.currentLoan.borrowerId === myUserProfile._id ? (
            <div className="ribbon ribbon">
              <span>Jag lånar</span>
            </div>
          ) : !!item.currentLoan ? (
            <div className="ribbon">
              <span>Utlånad</span>
            </div>
          ) : item.requested ? (
            <div className="ribbon ribbon-blue">
              <span>Sökes</span>
            </div>
          ) : item.give ? (
            <div className="ribbon ribbon-green">
              <span>Skänkes</span>
            </div>
          ) : (
            ''
          )}
          {!!suggestion ? (
            <CardTitle className="text-muted">
              {item.emoji} {item.name}
            </CardTitle>
          ) : (
            <CardTitle>
              {item.emoji} {item.name}{' '}
              {(item.historicLoans || []).length > 1 ? (
                <Emoji symbol="🏆" label="Denna sak har lånats mycket!" />
              ) : null}
            </CardTitle>
          )}

          <CardBody className="borrowit-card-body">
            {item.description && (
              <CardText className="text-muted">{item.description}</CardText>
            )}
            <CardText>
              {item.category && (
                <small className="float-left text-muted">
                  {getIconFromCategoryName(item.category)}
                  {` `}
                  {item.category}
                </small>
              )}

              {item.requested && item.showUntil && item.showUntil && (
                <small className="float-right text-muted">
                  {' '}
                  🗓 {getDateText(Date.parse(item.showUntil))}
                </small>
              )}

              {showTown && locationName && locationName !== '' && (
                <small className="float-right text-muted">
                  <Emoji symbol="📍" label="plats" />
                  {locationName}
                </small>
              )}
            </CardText>
          </CardBody>
        </ItemLink>

        <hr className="my-3" />
        <CardBody className="p-0">
          <div className="float-left">
            <ProfileLink profile={owner} />
            {!!item.currentLoan && item.currentLoan.borrower ? (
              <ProfileLink profile={item.currentLoan.borrower} />
            ) : item.currentLoan && item.currentLoan.borrowerId ? (
              <ProfileLink profile={borrower} />
            ) : (
              <div />
            )}
          </div>

          {/* Primary actions */}
          {!isMine &&
            (!item.requested ? (
              !!item.currentLoan ? (
                <ContactUserModal
                  outline
                  user={owner}
                  item={item}
                  reverse={item.requested}
                  className="float-right pr-0"
                  buttonText={'💬 Kontakta ' + owner.short_name}
                />
              ) : (
                <ContactUserModal
                  outline
                  user={owner}
                  item={item}
                  reverse={item.requested}
                  className="float-right pr-0"
                  buttonText="🙏 Fråga om lån"
                />
              )
            ) : (
              <ContactUserModal
                outline
                user={owner}
                item={item}
                reverse={item.requested}
                buttonText="🧡 Låna ut"
              />
            ))}

          {isMine &&
            !item.requested &&
            (!item.currentLoan ? (
              <OutloanModal
                outline
                user={owner}
                item={item}
                buttonText="🧡 Låna ut"
                className="float-right pr-0"
              />
            ) : (
              <Button
                color="link"
                className="float-right pr-0"
                onClick={() =>
                  props.returnLoanAsync({
                    itemId: item._id, // Needed for old loans
                    ...item.currentLoan,
                  })
                }
              >
                <Emoji symbol="↩️" /> Returnera
              </Button>
            ))}

          {/* Secondary actions */}
          {isMine && showExtendedActions && (
            <AddItemModal
              color="link"
              className="float-right pr-0"
              user={owner}
              buttonText="✏️ Ändra"
              editItem={item}
            />
          )}

          {isMine && !item.requested && (
            <BetaUsers>
              <GiveAway
                color="link"
                className="float-right pr-0"
                user={owner}
                buttonText="🎁 Skänk"
                item={item}
              />
            </BetaUsers>
          )}

          {isMine && showExtendedActions && (
            <Button
              color="link"
              className="float-right pr-0"
              onClick={() => props.removeItemAsync(owner, item)}
            >
              <Emoji symbol="🗑" /> Ta bort
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    myUserProfile: state.myUserProfile,
    userMap: state.items.userMap,
    isMine: item => state.myUserProfile._id === item.owner,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeItemAsync: (user, item) => {
      return dispatch(removeItemAsync(user, item));
    },
    returnLoanAsync: loan => {
      return dispatch(returnLoanAsync(loan));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
