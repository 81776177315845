import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { addItemAsync, setItemFilter } from '../reducers';
import AddItemModal from './AddItemModal';
import Emoji from './Emoji';
import ItemList from './ItemList';
import Loading from './Loading';
import NoItems from './NoItems';
import Search from './Search';

const Explore = (props) => (
  <>
    <Search onChange={props.handleChange} />
    {!props.isLoading &&
    (props.noOfFriends < 1 ||
      props.noOfAllItems < 1 ||
      props.noOfFriendItems < 1) ? (
      <Row className="text-center d-block mt-3">
        <NoItems
          noOfFriends={props.noOfFriends}
          noOfAllItems={props.noOfAllItems}
          noOfFriendItems={props.noOfFriendItems}
        />
      </Row>
    ) : null}
    <>
      {props.isLoading === true ? (
        <Loading />
      ) : (
        <>
          <>
            {props.noOfAllItems < 1 ? (
              ''
            ) : props.allItems.length < 1 ? (
              <Row className="d-block">
                <br />
                <div className="text-center">
                  <div
                    style={{
                      borderWidth: '1px',
                      borderRadius: '5px',
                      borderStyle: 'solid',
                      borderColor: '#dadada',
                      backgroundColor: '#f2f2f2',
                      padding: '20px',
                      margin: '20px',
                    }}
                  >
                    <p style={{ color: '#737373' }}>
                      Det du söker efter finns inte att låna{' '}
                      <Emoji symbol="😔" />
                    </p>
                    <p>
                      Låt dina vänner veta att du vill låna{' '}
                      <b>{props.filter.searchValue}</b>
                    </p>
                    <AddItemModal
                      requested
                      preset={{
                        name: props.filter.searchValue,
                        category: props.filter.categoryValue,
                      }}
                    />
                    <br />
                  </div>
                </div>
              </Row>
            ) : (
              <div className="mt-2">
                <ItemList items={props.allItems} showTown />
              </div>
            )}
          </>
          <Row className="text-center d-block mt-5">
            <hr />
            <p className="font-italic">
              Du kan låna {props.noOfFriendItems}{' '}
              {props.numberOfItems === 1 ? 'sak' : 'saker'}
              {' av '}
              {props.noOfFriendsWithItems}{' '}
              {props.noOfFriendsWithItems === 1 ? 'vän' : 'vänner'}{' '}
              <Emoji symbol="🎒" />
            </p>
          </Row>
        </>
      )}
    </>
  </>
);

const mapStateToProps = (state, ownProps) => {
  return {
    myUserProfile: state.myUserProfile,
    noOfFriends: state.myUserProfile.friends_list.length,
    noOfFriendsWithItems: state.items.userCount,
    noOfFriendItems: state.items.itemCount,
    noOfAllItems: Object.values(state.items.itemMap).filter(
      (item) => item.requested
    ).length,
    allItems: Object.values(state.items.itemMap)
      .map((item) => {
        item.owner_decoration = state.items.userMap[item.owner];
        return item;
      })
      .filter((item) => !item.requested)
      .filter((item) => matchesSearchValue(item, state))
      .sort((a, b) => sortItems(a, b, state)),
    isLoading: state.items.isLoading,
    filter: state.items.filter || {},
    locationName: (state.myUserProfile.location_name || '').split(',')[0],
  };
};

function sortItems(a, b, state) {
  const sortValue = (state.items.filter || {}).sortValue || '';
  if (sortValue === 'newest') {
    return new Date(b.createdAt) - new Date(a.createdAt);
  } else if (sortValue === 'oldest') {
    return new Date(a.createdAt) - new Date(b.createdAt);
  } else if (sortValue === 'popular') {
    return (b.historicLoans || []).length - (a.historicLoans || []).length;
  } else if (sortValue === 'name') {
    return a.name.localeCompare(b.name);
  } else {
    return new Date(b.createdAt) - new Date(a.createdAt);
  }
}

function matchesSearchValue(item, state) {
  const searchValue = (state.items.filter || {}).searchValue || '';
  const categoryValue = (state.items.filter || {}).categoryValue || '';
  const locationValue = (state.items.filter || {}).locationValue || '';
  const giveValue = (state.items.filter || {}).giveValue || '';

  const availableValue = (state.items.filter || {}).availableValue || '';
  const otherOwnerValue = (state.items.filter || {}).otherOwnerValue || '';

  return (
    item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 &&
    (categoryValue === '' ||
      (item.category || '').toLowerCase() === categoryValue.toLowerCase()) &&
    (locationValue === '' ||
      locationValue === item.owner_decoration.location_id) &&
    (giveValue === '' || (giveValue === 'true' && item.give)) &&
    (availableValue === '' ||
      (availableValue === 'true' && !!!item.currentLoan)) &&
    (otherOwnerValue === '' ||
      (otherOwnerValue === 'true' &&
        item.owner_decoration._id !== state.myUserProfile._id))
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (value) => {
      dispatch(setItemFilter(value));
    },
    addItemAsync: (event, item) => {
      event.preventDefault();
      return dispatch(addItemAsync(item));
    },
  };
};

Explore.propTypes = {
  noOfFriends: PropTypes.number,
  noOfAllItems: PropTypes.number,
  noOfFriendItems: PropTypes.number,
  noOfFriendsWithItems: PropTypes.number,
  numberOfItems: PropTypes.number,
  handleChange: PropTypes.func,
  isLoading: PropTypes.bool,
  allItems: PropTypes.any,
  filter: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
