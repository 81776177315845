import React from 'react';
import { Col, Row, Form, FormGroup, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import ExploreTags from './ExploreTags';
import { getCategories } from '../helpers';
import Emoji from './Emoji';
import QuickLoanModal from './QuickLoanModal';
import AddItemModal from './AddItemModal';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        searchValue: props.searchValue || '',
        categoryValue: props.categoryValue || '',
        sortValue: props.sortValue || '',
        locationValue: props.locationValue || '',
        availableValue: props.availableValue || '',
        giveValue: props.giveValue || '',
        popularValue: props.popularValue || '',
        otherOwnerValue: props.otherOwnerValue || '',
      },
      collapse: false,
      onChange: props.onChange,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);

    this.setSearchInputRef = React.createRef();
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  onChange(change) {
    const searchValue = this.props.propsFilter
      ? this.props.propsFilter.searchValue
      : '';
    if (change.searchValue === searchValue) {
      change.searchValue = '';
    }

    var filter = {
      ...this.state.filter,
      ...change,
    };

    this.setState({
      filter,
    });

    this.props.onChange(filter);
  }

  onSubmit(event) {
    const searchInputRef = this.setSearchInputRef.current;
    searchInputRef.blur();
    event.preventDefault();
  }

  render() {
    // const categoryValue = this.props.propsFilter ? this.props.propsFilter.categoryValue : ''
    const searchValue =
      this.props.propsFilter && this.props.propsFilter.searchValue
        ? this.props.propsFilter.searchValue
        : '';
    const locationValue = this.props.propsFilter
      ? this.props.propsFilter.locationValue
      : '';
    const availableValue = this.props.propsFilter
      ? this.props.propsFilter.availableValue
      : '';
    const giveValue = this.props.propsFilter
      ? this.props.propsFilter.giveValue
      : '';
    // const popularValue = this.props.propsFilter
    //   ? this.props.propsFilter.popularValue
    //   : '';
    const otherOwnerValue = this.props.propsFilter
      ? this.props.propsFilter.otherOwnerValue
      : '';

    var propsFilter = this.props.propsFilter ? this.props.propsFilter : {};
    var numberOfFilters = 0;
    Object.keys(propsFilter).map((key, index) => {
      return propsFilter[key] !== '' &&
        key !== 'sortValue' &&
        key !== 'searchValue'
        ? numberOfFilters++
        : '';
    });
    // const filterCount = numberOfFilters
    const popularItems = this.props.popularItems;

    return (
      <div className="text-center mt-3">
        <h2>Saker för utlåning</h2>
        <div className="text-center mb-3">
          <AddItemModal buttonText="📦 Lägg till sak" className="m-3" />
          <QuickLoanModal buttonText="💡 Registrera lån" color="primary" />
        </div>
        <Row>
          <Col
            xs="12"
            sm={{ size: 10, offset: 1 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
          >
            <Form onSubmit={this.onSubmit}>
              <FormGroup className="mb-2">
                <Input
                  type="search"
                  placeholder={
                    '🔍 Prova ' +
                    (popularItems && popularItems[0] && popularItems[1]
                      ? '"' +
                        popularItems[0].name +
                        '" eller "' +
                        popularItems[1].name +
                        '"'
                      : '"Resväska" eller "Squashracket"')
                  }
                  innerRef={this.setSearchInputRef}
                  value={searchValue}
                  onChange={(event) =>
                    this.onChange({ searchValue: event.target.value })
                  }
                />
                {/* <Button className='mb-1 ml-2' type='submit' color='primary'>Hitta</Button> */}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg={{ size: 10, offset: 1 }}>
            <div className="h-100 mx-auto">
              <div className="mb-2">
                {/* <h6 className='mt-3'>Populära sökningar</h6> */}
                {
                  <ExploreTags
                    onClick={this.onChange}
                    searchValue={searchValue}
                  />
                }

                <Button
                  key="showOnlyThingsInMyTownHighlight"
                  color="info"
                  size="sm"
                  className={
                    'mx-1 mb-1' +
                    (locationValue !== this.props.userLocationId
                      ? ''
                      : ' font-weight-bold border border-active') +
                    (this.props.userLocationId === null ? ' d-none' : '')
                  }
                  onClick={(event) =>
                    this.onChange({
                      locationValue:
                        locationValue !== this.props.userLocationId
                          ? this.props.userLocationId
                          : '',
                    })
                  }
                >
                  <Emoji symbol="🏠" label="Hus" />{' '}
                  {this.props.userLocationName}
                </Button>

                <Button
                  key="showOnlyAvailableThingsHighlight"
                  color="info"
                  size="sm"
                  className={
                    'mx-1 mb-1' +
                    (availableValue !== 'true'
                      ? ''
                      : ' font-weight-bold border border-active')
                  }
                  onClick={(event) =>
                    this.onChange({
                      availableValue: availableValue !== 'true' ? 'true' : '',
                    })
                  }
                >
                  <Emoji symbol="✅" label="Tillgängligt" /> Tillgängligt
                </Button>

                <Button
                  key="showOnlyThingsGivenHighlight"
                  color="info"
                  size="sm"
                  className={
                    'mx-1 mb-1' +
                    (giveValue !== 'true'
                      ? ''
                      : ' font-weight-bold border border-active')
                  }
                  onClick={(event) =>
                    this.onChange({
                      giveValue: giveValue !== 'true' ? 'true' : '',
                    })
                  }
                >
                  <Emoji symbol="‍‍💁‍" label="Skänkes" /> Skänkes
                </Button>

                <Button
                  key="showOnlyOthersThingsHighlight"
                  color="info"
                  size="sm"
                  className={
                    'mx-1 mb-1' +
                    (otherOwnerValue !== 'true'
                      ? ''
                      : ' font-weight-bold border border-active')
                  }
                  onClick={(event) =>
                    this.onChange({
                      otherOwnerValue: otherOwnerValue !== 'true' ? 'true' : '',
                    })
                  }
                >
                  <Emoji symbol="‍‍👫" label="Två vänner" /> Andras saker
                </Button>

                <FormGroup className="d-inline mb-1">
                  <Input
                    type="select"
                    name="categoryNew"
                    bsSize="sm"
                    className="d-inline mx-1 mb-1"
                    style={{ width: '165px' }}
                    onChange={(event) =>
                      this.onChange({ categoryValue: event.target.value })
                    }
                  >
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <option key="everything" value="">
                      🌱 Kategori
                    </option>
                    {getCategories().map((category) => (
                      <option key={category.name} value={category.name}>
                        {category.icon} {category.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                {/* <Button color='info'
                  className='mx-1 mb-1 text-left nohover'
                  style={{
                    'width': '140px'
                  }}
                  onClick={this.toggle}>
                  {this.state.collapse ? '👆' : '👇'} Fler filter {filterCount > 0 ? <small>({filterCount})</small> : ''}
                </Button> */}

                <FormGroup className="d-inline mb-1">
                  <Input
                    type="select"
                    name="sort"
                    bsSize="sm"
                    className="d-inline mx-1 mb-1"
                    style={{ width: '140px' }}
                    onChange={(event) =>
                      this.onChange({ sortValue: event.target.value })
                    }
                  >
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <option key="newest" value="newest">
                      👇 Senaste
                    </option>
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <option key="oldest" value="oldest">
                      👆 Äldsta
                    </option>
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <option key="popular" value="popular">
                      🏆 Populäraste
                    </option>
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <option key="name" value="name">
                      🔤 A-Ö
                    </option>
                  </Input>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Collapse isOpen={this.state.collapse}>
          <h6 className='mt-3'>Populära sökningar</h6>
          {<ExploreTags onClick={this.onChange}
            searchValue={searchValue} />}
          <h6 className='mt-3'>Filtrera efter kategorier</h6>
          <div >
            {getCategories().map(category =>
              <Button
                key={category.name}
                color='secondary'
                outline={categoryValue !== category.name}
                className='nohover'
                style={{
                  'font-size': '10px',
                  'margin': '5px',
                  'padding': '3px',
                  'color': '#4F4F4F',
                  'border-color': '#4F4F4F'
                }}
                onClick={(event) => this.onChange({
                  categoryValue: (categoryValue !== category.name ? category.name : '') })}>
                {category.icon} {category.name}
              </Button>
            )}
          </div>
        </Collapse> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    propsFilter: state.items.filter,
    popularItems: state.extra.popularItems,
    userLocationName: (state.myUserProfile.location_name || '').split(',')[0],
    userLocationId: state.myUserProfile.location_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
