import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { uploadImagesAsync } from '../reducers';
import Emoji from './Emoji';
import Loading from './Loading';

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      imageId: props.imageId,
    };
    this.setImageId = this.setImageId.bind(this);
  }

  onDrop(files) {
    this.setState({
      files,
      loading: true,
      imageId: undefined,
    });

    return this.props.uploadImagesAsync(files).then(response => {
      this.setImageId(
        response[0].id,
        response[0].width,
        response[0].height,
        response[0].type
      );
    });
  }

  setImageId(imageId, imageWidth, imageHeight, imageType) {
    this.setState({
      imageId: imageId,
      imageWidth: imageWidth,
      imageHeight: imageHeight,
      imageType: imageType,
    });
    this.props.onUploadComplete(imageId, imageWidth, imageHeight, imageType);
  }

  render() {
    return (
      <div>
        {this.state.imageId && (
          <img
            src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_limit,e_trim:10,h_200,r_5,w_300/v1/${this.state.imageId}.png`}
            onLoad={() =>
              this.setState({
                loading: false,
              })
            }
            onClick={() => this.setImageId(null)}
            style={this.state.loading ? { display: 'none' } : null}
            alt="Uppladdad bild"
          />
        )}
        {(!this.state.imageId || this.state.loading) && (
          <Dropzone className="dropzone" onDrop={this.onDrop.bind(this)}>
            {!this.state.loading && (
              <div>
                <p className="dropzone-intro mt-3">
                  Klart du ska ha en bild <Emoji symbol="📸" />
                </p>
                <p className="dropzone-action">
                  Dra hit eller klicka för att ladda upp
                </p>
              </div>
            )}

            {this.state.loading && (
              <div>
                <p className="dropzone-intro mt-3">
                  Laddar upp bild... <Emoji symbol="💆" />‍
                </p>
                <Loading />
              </div>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    uploadImagesAsync: images => {
      return dispatch(uploadImagesAsync(images));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader);
