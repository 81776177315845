import React from 'react';
import { Alert, Button } from 'reactstrap';
import Emoji from './Emoji';
import LoginButton from './LoginButton';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileSettingAsync } from '../reducers';

const settingKey = 'ignore_missing_fb_permissions';
/**
 * Alerts the user about missing facebook permissions
 *
 * TODO: distinguish required and non-requiread permissions
 * Note to self: permission status can now be granted, declined or expired
 *
 * @param {Array} declinedPermissions - List of the declined facebook permissions.
 */
const PermissionsAlert = () => {
  const declinedPermissions = useSelector(store =>
    (store.myUserProfile.facebook_permissions || []).filter(
      permission => permission.status !== 'granted'
    )
  );

  const ignoreMissingFbPermissions = useSelector(
    store => (store.myUserProfile.settings || [])[settingKey] || false
  );

  const dispatch = useDispatch();

  if (ignoreMissingFbPermissions) return null;

  return (
    <>
      {!declinedPermissions || declinedPermissions.length < 1 ? (
        ''
      ) : (
        <Alert color="danger" className="m-5">
          <h4 className="alert-heading">Oj!</h4>
          <p>
            Det verkar inte som att vi har den information om dig som vi behöver
            för att du ska kunna använda appen på ett korrekt sätt. Se gärna
            över om det är nån mer info du vill dela med oss.
            <Emoji symbol="😇" />
          </p>
          <hr />
          <p>
            Att dela information med oss är såklart frivilligt, men här nedan är
            en förklaring varför vi efterfrågar informationen:
            <ul>
              {declinedPermissions.map(permission => {
                return (
                  <li key={permission.permission}>
                    {permission.permission
                      .replace('public_profile', 'Offentlig profil')
                      .replace(
                        'user_friends',
                        'Vänlista för att kunna se vad dina vänner lånar ut.'
                      )
                      .replace(
                        'user_location',
                        'Nuvarande ort för att kunna se saker i närheten.'
                      )
                      .replace(
                        'email',
                        'E-postadress så vi kan nå dig på mailen.'
                      )
                      .replace(
                        'user_gender',
                        'Kön för att det ska bli rätt pronomen på sidan.'
                      )
                      .replace(
                        'user_link',
                        'Länk till din profil så dina vänner enkelt kan kontakta dig.'
                      )}
                  </li>
                );
              })}
            </ul>
          </p>
          <LoginButton authTypeRerequest />{' '}
          <Button
            className="btn-landing small"
            onClick={() =>
              dispatch(setProfileSettingAsync(settingKey, true))
                .then(() => console.log('Successfully set setting'))
                .catch(err => console.log('Failed to set setting'))
            }
          >
            <Emoji symbol="🤞" /> Nej tack jag fortsätter ändå.
          </Button>
        </Alert>
      )}
    </>
  );
};

export default PermissionsAlert;
