import { useSelector } from 'react-redux';
import { useLocalStorage } from '../hooks';

/**
 * Renders children if the beta flag and value exists in the state.
 */
const BetaUsers = ({ prodUsers, children }) => {
  const isUserBetaTester =
    useSelector(store => store.myUserProfile.settings.beta_tester) === true;

  const [isLocalBetaTester] = useLocalStorage('beta', 'false');
  const isBetaTester = isUserBetaTester || isLocalBetaTester === 'true';

  return (isBetaTester && !prodUsers) || (!isBetaTester && prodUsers)
    ? children
    : null;
};

export default BetaUsers;
