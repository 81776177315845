import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

class PopoverButton extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  render() {
    return (
      <div className="d-inline ml-2">
        <Button
          size="sm"
          color="info"
          id="Popover1"
          style={{
            borderRadius: '2rem',
            fontSize: '0.6rem',
            fontWeight: 'bold',
          }}
          onClick={this.toggle}
        >
          {this.props.buttonText}
        </Button>
        <Popover
          placement="auto"
          isOpen={this.state.popoverOpen}
          target="Popover1"
          toggle={this.toggle}
        >
          <PopoverHeader>{this.props.popoverHeaderText}</PopoverHeader>
          <PopoverBody>{this.props.popoverText}</PopoverBody>
        </Popover>
      </div>
    );
  }
}

PopoverButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  popoverHeaderText: PropTypes.string.isRequired,
  popoverText: PropTypes.string.isRequired,
};

export default PopoverButton;
