import { format } from 'date-fns';
import React, { useState } from 'react';
import { Input } from 'reactstrap';

const DatePicker = ({ onChange, initialDate, invalid }) => {
  const [date, setDate] = useState(initialDate || new Date());

  const handleDateUpdate = event => {
    setDate(new Date(event.target.value));
    onChange(new Date(event.target.value));
  };

  return (
    <Input
      type="date"
      invalid={invalid}
      value={format(date, 'yyyy-MM-dd')}
      onChange={handleDateUpdate}
    />
  );
};

export default DatePicker;
