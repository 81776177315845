import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class TabLayout extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <div>
        <Nav tabs>
          {this.props.children.map((child, index) => {
            return (
              <NavItem key={child.props.title}>
                <NavLink
                  href="#"
                  className={classnames({
                    active: this.state.activeTab === index,
                  })}
                  onClick={() => {
                    this.toggle(index);
                  }}
                >
                  <h5>{child.props.title}</h5>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {this.props.children.map((child, index) => {
            return (
              <TabPane key={child.props.title} tabId={index}>
                <div>{child}</div>
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    );
  }
}

export default TabLayout;
