import { connectRouter } from 'connected-react-router';
import ReactGA from 'react-ga';
import { combineReducers } from 'redux';
import { SET_ITEM_FILTERING } from './actions/actions.js';

export const FETCH_FEED_REQUEST = 'FETCH_FEED_REQUEST';
export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS';
export const FETCH_FEED_FAILURE = 'FETCH_FEED_FAILURE';

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

export const FETCH_POPULAR_ITEMS_REQUEST = 'FETCH_POPULAR_ITEMS_REQUEST';
export const FETCH_POPULAR_ITEMS_SUCCESS = 'FETCH_POPULAR_ITEMS_SUCCESS';
export const FETCH_POPULAR_ITEMS_FAILURE = 'FETCH_POPULAR_ITEMS_FAILURE';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const EDIT_MYITEMS_SUCCESS = 'EDIT_MYITEMS_SUCCESS';
export const EDIT_MYITEMS_FAILED = 'EDIT_MYITEMS_FAILED';

export const REGISTER_LOAN_REQUEST = 'REGISTER_LOAN_FAILED';
export const REGISTER_LOAN_FAILED = 'REGISTER_LOAN_FAILED';
export const REGISTER_LOAN_SUCCESS = 'REGISTER_LOAN_SUCCESS';
export const RETURN_LOAN_FAILED = 'RETURN_LOAN_FAILED';
export const RETURN_LOAN_SUCCESS = 'RETURN_LOAN_SUCCESS';

export const SET_PROFILE_SETTING_FAILED = 'SET_PROFILE_SETTING_FAILED';
export const SET_PROFILE_SETTING_SUCCESS = 'SET_PROFILE_SETTING_SUCCESS';

export const UPDATE_MYITEMS_FAILED = 'UPDATE_MYITEMS_FAILED';
export const ADD_MYITEMS_SUCCESS = 'ADD_MYITEMS_SUCCESS';

export const SET_ITEM_FILTER = 'SET_ITEM_FILTER';

export const ADD_ITEM_REQUEST = 'ADD_ITEM_REQUEST';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM_REQUEST = 'REMOVE_ITEM_REQUEST';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAILED = 'REMOVE_ITEM_FAILED';

export const USER_LOGIN_CLICKED = 'USER_LOGIN_CLICKED';
export const SET_FB_ACCESS_TOKEN = 'SET_FB_ACCESS_TOKEN';
export const USER_LOGOUT = 'USER_LOGOUT';

// const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

function showItemFilter(state = 'abc', action) {
  switch (action.type) {
    case SET_ITEM_FILTERING:
      return action.filter;
    default:
      return state;
  }
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '/api';

function auth(
  state = {
    isLoggingIn: false,
    isLoggedIn: false,
    fbAccessToken: '',
    apiVersion: '',
  },
  action
) {
  console.log('ActionApi', action.type, action);

  switch (action.type) {
    case SET_FB_ACCESS_TOKEN:
      return {
        ...state,
        ...action,
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
      };

    default:
      return state;
  }
}

function myUserProfile(state = { isLoading: true }, action) {
  console.log(action.type, action);

  switch (action.type) {
    case EDIT_MYITEMS_SUCCESS:
    case EDIT_MYITEMS_FAILED:
    case UPDATE_MYITEMS_FAILED:
    case ADD_MYITEMS_SUCCESS:
      return {
        ...state,
        ...action,
      };

    case FETCH_PROFILE_FAILURE:
    case FETCH_PROFILE_SUCCESS:
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        ...action,
      };

    case SET_PROFILE_SETTING_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action,
        },
      };

    default:
      return state;
  }
}

function items(
  state = { isLoading: true, userMap: {}, itemMap: {}, filter: {} },
  action
) {
  console.log(action.type, action);

  switch (action.type) {
    case EDIT_MYITEMS_SUCCESS:
    case REGISTER_LOAN_SUCCESS:
    case RETURN_LOAN_SUCCESS:
    case ADD_MYITEMS_SUCCESS:
      const tmp = {
        ...state,
        itemMap: { ...state.itemMap },
      };

      tmp.itemMap[action.updatedItem._id] = action.updatedItem;
      return tmp;

    case SET_ITEM_FILTER:
      return {
        ...state,
        filter: action.filter,
      };

    case FETCH_ITEMS_REQUEST:
      return state;

    case FETCH_ITEMS_SUCCESS:
      return action;

    case FETCH_ITEMS_FAILURE:
      return state;

    case ADD_ITEM_REQUEST:
      return state;

    case ADD_ITEM:
      return state;

    case REMOVE_ITEM_REQUEST:
      return state;

    case REMOVE_ITEM_SUCCESS:
      const removeTmp = {
        ...state,
        itemMap: { ...state.itemMap },
      };

      delete removeTmp.itemMap[action.removedItem._id];
      return removeTmp;

    case REMOVE_ITEM_FAILED:
      return state;

    default:
      return state;
  }
}

function feed(state = { isLoading: true, events: [] }, action) {
  console.log(action.type, action);

  switch (action.type) {
    case FETCH_FEED_REQUEST:
      return state;

    case FETCH_FEED_SUCCESS:
      return action;

    case FETCH_FEED_FAILURE:
      return state;

    default:
      return state;
  }
}

function extra(state = { isLoading: false, popularItems: [] }, action) {
  console.log(action.type, action);

  switch (action.type) {
    case FETCH_POPULAR_ITEMS_REQUEST:
      return state;

    case FETCH_POPULAR_ITEMS_SUCCESS:
      return action;

    case FETCH_POPULAR_ITEMS_FAILURE:
      return state;

    default:
      return state;
  }
}

// function flatten(a) {
//   return [].concat.apply([], a);
// }

export const fetchItemsAsync = () => {
  // var userFriends = {};
  return (dispatch, getState) => {
    console.log('fetchItemsAsync');
    dispatch({
      type: FETCH_ITEMS_REQUEST,
      isLoading: true,
    });
    return apiGetFriends(getState().auth.fbAccessToken)
      .then(userFriends => {
        console.log('fetchedfriends', typeof userFriends, userFriends);
        let userMap = {};
        userFriends.forEach(friend => {
          userMap[friend._id] = {
            ...friend,
            items: null,
          };
        });
        let itemMap = {};
        userFriends.forEach(user =>
          (user.items || []).forEach(item => (itemMap[item._id] = item))
        );

        return {
          itemMap: itemMap,
          userMap: userMap,
        };
      })
      .then(maps => {
        ReactGA.event({
          category: 'App',
          action: 'Friend Items',
          label: Object.keys(maps.userMap).length.toString(),
          value: Object.keys(maps.userMap).length,
        });
        ReactGA.event({
          category: 'App',
          action: 'Friends With Items',
          label: Object.keys(maps.itemMap).length.toString(),
          value: Object.keys(maps.itemMap).length,
        });
        console.log('Fetched users and items', maps);
        dispatch({
          type: FETCH_ITEMS_SUCCESS,
          isLoading: false,
          userMap: maps.userMap,
          userCount: Object.keys(maps.userMap).length,
          itemMap: maps.itemMap,
          itemCount: Object.keys(maps.itemMap).length,
        });
      });
  };
};

export const fetchPopularItemsAsync = () => {
  return (dispatch, getState) => {
    console.log('fetchPopularItemsAsync');
    dispatch({
      type: FETCH_POPULAR_ITEMS_REQUEST,
      isLoading: true,
    });
    return apiGetPopularItems(getState().auth.fbAccessToken)
      .then(popularItems => {
        console.log('fetched popular items', popularItems);
        dispatch({
          type: FETCH_POPULAR_ITEMS_SUCCESS,
          isLoading: false,
          popularItems: popularItems,
        });
      })
      .catch(err => {
        console.log('ERROR fetched popular items', err);
        dispatch({
          type: FETCH_POPULAR_ITEMS_FAILURE,
          isLoading: false,
        });
      });
  };
};

const borrowIt = history => (state, action) => {
  if (action && action.type === USER_LOGOUT) {
    state = undefined;
  }

  return combineReducers({
    router: connectRouter(history),
    showItemFilter,
    myUserProfile,
    items,
    extra,
    auth,
    feed,
  })(state, action);
};

var timer;
export function setItemFilter(filter) {
  console.log('setItemFilter', filter);
  clearTimeout(timer);
  if (filter && filter.searchValue && filter.searchValue !== '') {
    timer = setTimeout(function() {
      console.log('setItemFilter, sending GA search event: ', filter);
      ReactGA.event({
        category: 'Engagement',
        action: 'Search',
        label: filter.searchValue,
      });
    }, 1500);
  }
  return { type: SET_ITEM_FILTER, filter: filter };
}

export function facebookLoginClicked(authTypeRerequest) {
  return dispatch => {
    console.log('facebookLoginClicked');
    ReactGA.event({
      category: 'Engagement',
      action: 'Login Clicked',
      label: authTypeRerequest ? 'Facebook Rerequest' : 'Facebook',
    });
    dispatch({
      type: USER_LOGIN_CLICKED,
    });
  };
}

export function handleFacebookLogin(response, authTypeRerequest) {
  return dispatch => {
    console.log('handleFacebookLogin', response);
    if (
      typeof response.accessToken === 'undefined' ||
      response.accessToken === null
    ) {
      console.log('Logging out user because failed logging in.', response);
      return { type: USER_LOGOUT, undefined };
    } else {
      console.log('Successfully FB log in.', response);
      ReactGA.event({
        category: 'Engagement',
        action: 'Login',
        label: authTypeRerequest ? 'Facebook Rerequest' : 'Facebook',
      });

      dispatch({
        type: SET_FB_ACCESS_TOKEN,
        fbAccessToken: response.accessToken,
        isLoggedIn: false,
        isLoggingIn: true,
      });
    }
  };
}

export function handleUserLogout() {
  console.log('Logging out user because of user request');
  ReactGA.event({
    category: 'Engagement',
    action: 'Logout',
    label: 'Facebook',
  });
  return { type: USER_LOGOUT, undefined };
}

export const getAccessTokenFromLocalStorage = () => {
  return (dispatch, getState) => {
    const fbAccessToken = localStorage.getItem('fbAccessToken');
    if (fbAccessToken) {
      dispatch({
        type: SET_FB_ACCESS_TOKEN,
        fbAccessToken: fbAccessToken,
      });
    }
  };
};

export const fetchProfileAsync = () => {
  console.log('fetchProfileAsync');

  return (dispatch, getState) => {
    console.log('fetchProfileAsync');
    dispatch({
      type: FETCH_PROFILE_REQUEST,
      isLoading: true,
    });
    // const fbAccessToken = localStorage.getItem('fbAccessToken')
    // console.log('Token', fbAccessToken)
    // if (fbAccessToken) {
    //   dispatch({
    //     type: SET_FB_ACCESS_TOKEN,
    //     fbAccessToken: fbAccessToken
    //   })
    if (getState().auth.fbAccessToken) {
      return apiGetProfile(getState().auth.fbAccessToken).then(rawProfile =>
        rawProfile.json().then(profile => {
          let apiVersion = rawProfile.headers.get('X-Api-Version');
          console.log('ApiVersion', apiVersion);
          if (!profile.username) {
            console.log('Something went wrong there!', profile);
            dispatch({
              type: FETCH_PROFILE_FAILURE,
              isLoading: false,
            });
            console.log('Logging out user because error on fetching profile.');
            dispatch({
              type: USER_LOGOUT,
              undefined,
            });
          } else {
            console.log('Successful fetch profile', profile);
            // profile.friends.length = no of facebook friends registered on lets borrowit
            // dimension2: profile.friends.length, waiting to set the dim 2 for a while..
            ReactGA.set({
              userId: profile._id,
              dimension1: profile._id,
            });
            if (profile.first_login) {
              ReactGA.event({
                category: 'Engagement',
                action: 'Sign Up',
                label: 'Facebook',
              });
            }
            ReactGA.event({
              category: 'App',
              action: 'Own Items',
              label: profile.items.length.toString(),
              value: profile.items.length,
            });
            ReactGA.event({
              category: 'App',
              action: 'Friends',
              label: profile.friends.length.toString(),
              value: profile.friends.length,
            });
            delete profile.items;
            delete profile.friends;
            dispatch({
              type: FETCH_PROFILE_SUCCESS,
              apiVersion: apiVersion,
              ...profile,
              isLoading: false,
            });
            dispatch(fetchItemsAsync());
            return dispatch(fetchFeedAsync());
          }
        })
      );
    } else {
      console.log('Logging out user because no session token');
      dispatch({
        type: USER_LOGOUT,
        undefined,
      });
    }
  };
};

export const setProfileSettingAsync = (key, value) => {
  const settings = {};
  settings[key] = value;
  return (dispatch, getState) =>
    apiSetSetting(getState().auth.fbAccessToken, settings)
      .then(data => {
        console.log('Successfully set settings', settings);
        dispatch({
          type: SET_PROFILE_SETTING_SUCCESS,
          ...settings,
        });
        return Promise.resolve();
      })
      .catch(data => {
        console.log('Failed set settings', settings);
        dispatch({
          type: SET_PROFILE_SETTING_FAILED,
        });
        return Promise.reject();
      });
};

/***************************************************************************************************
     ___      .__   __.      ___       __      ____    ____ .___________. __    ______     _______.
    /   \     |  \ |  |     /   \     |  |     \   \  /   / |           ||  |  /      |   /       |
   /  ^  \    |   \|  |    /  ^  \    |  |      \   \/   /  `---|  |----`|  | |  ,----'  |   (----`
  /  /_\  \   |  . `  |   /  /_\  \   |  |       \_    _/       |  |     |  | |  |        \   \    
 /  _____  \  |  |\   |  /  _____  \  |  `----.    |  |         |  |     |  | |  `----.----)   |   
/__/     \__\ |__| \__| /__/     \__\ |_______|    |__|         |__|     |__|  \______|_______/    
****************************************************************************************************/
function gaEventItem(itemName, subaction) {
  let category = 'Engagement';
  let action = 'Item - ' + subaction;
  let label = itemName;
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

/******************************************************************************
 _______   __       _______..______      ___   .___________.  ______  __    __  
|       \ |  |     /       ||   _  \    /   \  |           | /      ||  |  |  | 
|  .--.  ||  |    |   (----`|  |_)  |  /  ^  \ `---|  |----`|  ,----'|  |__|  | 
|  |  |  ||  |     \   \    |   ___/  /  /_\  \    |  |     |  |     |   __   | 
|  '--'  ||  | .----)   |   |  |     /  _____  \   |  |     |  `----.|  |  |  | 
|_______/ |__| |_______/    | _|    /__/     \__\  |__|      \______||__|  |__| 
********************************************************************************/
export const addItemAsync = (item, id, isSuggestion) => {
  console.log('addItemAsync');
  const declinedSuggestion = item.dontSave;

  return (dispatch, getState) =>
    apiPostNewItem(getState().auth.fbAccessToken, item)
      .then(response => response.json())
      .then(data => {
        console.log('Successfully added item item', item);
        gaEventItem(item.name, declinedSuggestion ? 'Declined' : 'Add');

        if (!!data.justAddedItem) {
          dispatch({
            type: ADD_MYITEMS_SUCCESS,
            items: data.items,
            updatedItem: data.justAddedItem,
          });
        }

        return Promise.resolve(data.justAddedItem);
      })
      .catch(data => {
        console.log('Error when adding item', data);
        dispatch({
          type: UPDATE_MYITEMS_FAILED,
        });

        return Promise.reject();
      });
};

export const removeItemAsync = (user, item) => {
  console.log('removeItemAsync', item);

  return (dispatch, getState) => {
    apiDeleteItem(getState().auth.fbAccessToken, item._id)
      .then(response => response.json())
      .then(data => {
        gaEventItem(item.name, 'Remove');

        return dispatch({
          type: REMOVE_ITEM_SUCCESS,
          removedItem: item,
        });
      })
      .catch(data => {
        return dispatch({
          type: REMOVE_ITEM_FAILED,
        });
      });
  };
};

export const editItemAsync = item => {
  console.log('editItemAsync', item);
  delete item.owner_decoration;

  return (dispatch, getState) => {
    return apiPostItemUpdate(getState().auth.fbAccessToken, item)
      .then(response => response.json())
      .then(data => {
        let GAaction = item.triggerReturnEvent
          ? 'Item - Return'
          : item.triggerOutloanedEvent
          ? 'Item - Outloan'
          : 'Item - Edit';

        ReactGA.event({
          category: 'Engagement',
          action: GAaction,
          label: item.name,
          ...extra,
        });
        dispatch({
          type: EDIT_MYITEMS_SUCCESS,
          items: data.items,
          updatedItem: data.justUpdatedItem,
          ...extra,
        });
        return Promise.resolve();
      })
      .catch(data => {
        console.log('error when updating item', data);
        dispatch({
          type: EDIT_MYITEMS_FAILED,
          ...extra,
        });
        return Promise.reject();
      });
  };
};

export const uploadImagesAsync = files => {
  console.log('uploadFileAsync', files);
  var formData = new FormData();
  formData.append('files', files[0]);
  console.log('formData', formData);

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      console.log('SEnddin');
      return apiPostImageUpload(getState().auth.fbAccessToken, formData)
        .then(response => resolve(response.json()))
        .catch(error => console.log(error));
    });
  };
};

export const fetchFeedAsync = () => {
  return (dispatch, getState) => {
    console.log('fetchFeedAsync');
    dispatch({
      type: FETCH_FEED_REQUEST,
      isLoading: true,
    });
    return apiGetFeed(getState().auth.fbAccessToken)
      .then(response => response.json())
      .then(feed => {
        console.log('fetched feed', feed);
        dispatch({
          type: FETCH_FEED_SUCCESS,
          isLoading: false,
          ...feed,
        });
      })
      .catch(err => {
        console.log('FAILED fetched feed', err);
        dispatch({
          type: FETCH_FEED_FAILURE,
          isLoading: false,
          events: [],
        });
      });
  };
};

export const registerLoanAsync = loan => {
  console.log('registerLoanAsync', loan);

  return (dispatch, getState) => {
    console.log('dispatched', loan);
    dispatch({
      type: REGISTER_LOAN_REQUEST,
      loan: loan,
    });

    return apiRegisterItemLoan(getState().auth.fbAccessToken, loan)
      .then(data => {
        console.log('registered loan:', data);
        return dispatch({
          type: REGISTER_LOAN_SUCCESS,
          updatedItem: data.justUpdatedItem,
        });
      })
      .catch(data => {
        console.log('error when registering loan', data);
        return dispatch({
          type: REGISTER_LOAN_FAILED,
          loan: loan,
        });
      });
  };
};

export const returnLoanAsync = loan => {
  console.log('returnLoanAsync', loan);
  return (dispatch, getState) => {
    console.log('dispatched', loan);

    return apiReturnItemLoan(getState().auth.fbAccessToken, loan)
      .then(data =>
        dispatch({
          type: RETURN_LOAN_SUCCESS,
          updatedItem: data.justUpdatedItem,
        })
      )
      .catch(data => {
        console.log('Error when returning loan.', data);
        return dispatch({
          type: RETURN_LOAN_FAILED,
        });
      });
  };
};

/****************************************************************************************************
.______        ___       ______  __  ___  _______ .__   __.  _______          ___      .______    __  
|   _  \      /   \     /      ||  |/  / |   ____||  \ |  | |       \        /   \     |   _  \  |  | 
|  |_)  |    /  ^  \   |  ,----'|  '  /  |  |__   |   \|  | |  .--.  |      /  ^  \    |  |_)  | |  | 
|   _  <    /  /_\  \  |  |     |    <   |   __|  |  . `  | |  |  |  |     /  /_\  \   |   ___/  |  | 
|  |_)  |  /  _____  \ |  `----.|  .  \  |  |____ |  |\   | |  '--'  |    /  _____  \  |  |      |  | 
|______/  /__/     \__\ \______||__|\__\ |_______||__| \__| |_______/    /__/     \__\ | _|      |__|                                                                                                    
******************************************************************************************************/

function apiPostNewItem(token, item) {
  return fetch(SERVER_URL + '/items', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(item),
  }).then(assertOk);
}

function apiPostItemUpdate(token, item) {
  console.log('Decoration', item);
  return fetch(SERVER_URL + '/items/' + item._id, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(item),
  }).then(assertOk);
}

function apiDeleteItem(token, itemId) {
  return fetch(SERVER_URL + '/items/' + itemId, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  }).then(assertOk);
}

function apiGetFriends(token) {
  return fetch(SERVER_URL + '/users/friends', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(assertOk)
    .then(response => response.json());
}

function apiGetProfile(token) {
  return fetch(SERVER_URL + '/profile/fb', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
}

function apiSetSetting(token, settings) {
  return fetch(SERVER_URL + '/profile/setting', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(settings),
  }).then(assertOk);
}

function apiGetPopularItems(token) {
  return fetch(SERVER_URL + '/items/popular', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(assertOk)
    .then(response => response.json());
}

function apiPostImageUpload(token, formData) {
  return fetch(SERVER_URL + '/images/upload', {
    method: 'post',
    headers: {
      Authorization: token,
    },
    body: formData,
  }).then(assertOk);
}

function apiGetFeed(token) {
  return fetch(SERVER_URL + '/activity/feed', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  }).then(assertOk);
}

function apiRegisterItemLoan(token, loan) {
  return fetch(SERVER_URL + '/items/' + loan.itemId + '/loan/register', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(loan),
  })
    .catch(e => console.log('Error when calling apiRegisterItemLoan', e))
    .then(assertOk)
    .then(response => response.json());
}

function apiReturnItemLoan(token, loan) {
  return fetch(SERVER_URL + '/items/' + loan.itemId + '/loan/return', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(loan),
  })
    .then(assertOk)
    .then(response => response.json());
}

function assertOk(response) {
  if (response.ok) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}

export default borrowIt;
