import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Item from './Item';

/**
 * To use ItemView, use
 * <Link to={routes.ITEM + '/' + item._id} >
 *   Link to View
 * </Link>
 *
 * TODO: if item = {} show error message or something
 */
const ItemView = ({ item }) => {
  // <Link to={routes.ITEM + '/' + item._id}>Item View</Link>
  return (
    <div
      style={{
        width: '300px',
        margin: 'auto',
      }}
    >
      {item && (
        <Item
          key={item._id}
          item={item}
          user={item.owner_decoration}
          showTown
          showExtendedActions
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    item: Object.values(state.items.itemMap).filter(
      o => o._id === ownProps.match.params.id
    )[0],
  };
};

ItemView.propTypes = {
  item: PropTypes.any,
};

export default connect(mapStateToProps)(ItemView);
