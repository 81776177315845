import { addMonths } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { getCategories } from '../helpers';
import { addItemAsync, editItemAsync } from '../reducers';
import DatePicker from './DatePicker';
import Emoji from './Emoji';
import ImageUploader from './ImageUploader';
import Loading from './Loading';
import SimpleButtonModal from './SimpleButtonModal';

const AddItemPage = ({
  editItem,
  placeholder,
  requested,
  hideModal,
  preset,
}) => {
  const initialItemState = {
    name: '',
    category: '',
    description: '',
    showUntil: !requested ? null : addMonths(new Date(), 1),
    give: false,
    requested: requested,
    ...preset,
  };

  // Used as a unique identifier for the form to know which was succeeded or not.
  const [formId] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);

  // Errors will initially not be shown. If trying to submit they will show up.
  const [showErrors, setShowErrors] = useState(false);

  // When the request has been sent these messages will be shown on success/error
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();

  // The object that will be sent to the api.
  const [item, setItem] = useState(editItem || initialItemState);

  const isEditing = !!editItem;

  const suggestion = useSelector(
    store =>
      Object.values(store.items.itemMap)
        .filter(item => item.requested)
        .filter(item => !!item.name)
        .map(item => {
          return {
            itemName: item.name || 'något',
            userName: store.items.userMap[item.owner].name || 'Någon',
          };
        })[0] || {}
  );

  const dispatch = useDispatch();

  const validateInput = field => {
    const validationRules = [
      {
        field: 'name',
        text: 'Du har inte skrivit något 🧐',
        condition: !item.name || item.name === '',
      },
      {
        field: 'name',
        text: 'Det där var en väldigt lång titel.. 🧐',
        condition: item.name && item.name.length > 100,
      },
      {
        field: 'showUntil',
        text: 'Datumet har redan passerat. 🙈',
        condition: !!item.showUntil && item.showUntil < Date.now(),
      },
    ];
    return validationRules
      .filter(rules => !field || rules.field === field)
      .filter(rule => rule.condition)
      .map(rule => rule.text);
  };

  const onSubmit = event => {
    setSuccessMessage(null);
    setFailedMessage(null);

    event.preventDefault();
    if (validateInput().length > 0) {
      console.log('Validation error', validateInput());
      return setShowErrors(true);
    }

    setIsLoading(true);

    if (isEditing) {
      return dispatch(editItemAsync(item))
        .then(() => hideModal())
        .catch(() =>
          setFailedMessage(
            <>
              Oops, något gick fel <Emoji symbol="😞" />
            </>
          )
        )
        .finally(() => setIsLoading(false));
    } else {
      return dispatch(addItemAsync(item))
        .then(() =>
          setSuccessMessage(
            <>
              <b>{item.name}</b> är tillagd!
              <Emoji symbol="🙃" />
            </>
          )
        )
        .then(() => setItem(initialItemState))
        .catch(() =>
          setFailedMessage(
            <>
              Oops, något gick fel <Emoji symbol="😞" />
            </>
          )
        )
        .finally(() => setIsLoading(false));
    }
  };

  const titlePlaceholderText =
    'Tex: ' + (placeholder || suggestion.itemName || 'Fotbollsskor');
  const descriptionPlaceholderText = 'Tex: Adidas. Storlek 43.';

  const isRequested = !!editItem ? editItem.requested : requested;

  const handleChange = event => {
    const updatedItem = { ...item };

    if (!!event.target && event.target.id === 'giveCheckbox') {
      updatedItem[event.target.name] = event.target.checked;
    } else if (event.name === 'showUntil') {
      updatedItem[event.name] = new Date(event.value);
    } else if (event.name === 'image_id') {
      updatedItem['image_id'] = event.value.cloudinary_id;
      updatedItem['image'] = { ...event.value };
    } else {
      updatedItem[event.target.name] = event.target.value;
    }
    console.log('Item1', event);

    console.log('Item2', updatedItem);
    setItem(updatedItem);
  };

  if (isLoading) return <Loading />;

  return (
    <>
      {!!successMessage ? (
        <Alert color="success" className="m-3">
          {successMessage}
        </Alert>
      ) : null}

      {!!failedMessage ? (
        <Alert color="danger" className="m-3">
          {failedMessage} <Emoji symbol="😞" />
        </Alert>
      ) : null}
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="exampleText">Titel*</Label>
          <Input
            type="text"
            //   ref="itemname"
            name="name"
            value={item.name}
            invalid={showErrors && validateInput('name').length > 0}
            placeholder={titlePlaceholderText}
            onChange={handleChange}
          />
          {validateInput('name').map((text, index) => (
            <FormFeedback key={index}>{text}</FormFeedback>
          ))}
        </FormGroup>

        <FormGroup>
          <Label for="exampleSelectMulti">Kategori</Label>
          <Input type="select" name="category" onChange={handleChange}>
            <option value="">Välj kategori</option>
            {getCategories().map((category, index) =>
              item.category === category.name ? (
                <option key={index} value={category.name} selected>
                  {category.icon} {category.name}
                </option>
              ) : (
                <option key={index} value={category.name}>
                  {category.icon} {category.name}
                </option>
              )
            )}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="exampleText">Beskrivning</Label>
          <Input
            type="textarea"
            name="description"
            value={item.description}
            placeholder={descriptionPlaceholderText}
            onChange={handleChange}
          />
        </FormGroup>

        {!isRequested ? (
          <FormGroup check>
            <Input
              type="checkbox"
              id="giveCheckbox"
              name="give"
              checked={!!item.give}
              onChange={handleChange}
            />
            <Label check for="giveCheckbox">
              Bortskänkes <Emoji symbol="😇" />
            </Label>
          </FormGroup>
        ) : null}

        {isRequested ? (
          <FormGroup>
            <Label for="exampleText">Förfrågan kommer försvinna: </Label>
            <DatePicker
              invalid={showErrors && validateInput('showUntil').length > 0}
              initialDate={!!item.showUntil ? Date.parse(item.showUntil) : null}
              onChange={d => handleChange({ name: 'showUntil', value: d })}
            />
            {validateInput('showUntil').map((text, index) => (
              <FormFeedback key={index}>{text}</FormFeedback>
            ))}
          </FormGroup>
        ) : null}

        {!isRequested ? (
          <FormGroup className="my-3">
            <ImageUploader
              key={formId}
              imageId={item.image_id}
              onUploadComplete={(imageId, imageWidth, imageHeight, imageType) =>
                handleChange({
                  name: 'image_id',
                  value: {
                    cloudinary_id: imageId,
                    width: imageWidth,
                    height: imageHeight,
                    type: imageType,
                  },
                })
              }
            />
          </FormGroup>
        ) : null}

        <Button color="primary">
          <Emoji symbol="☝️" /> {!isEditing ? 'Lägg till' : 'Uppdatera'}
        </Button>
      </Form>

      {!isRequested && !isEditing ? (
        <p className="text-center">
          <b>{suggestion.userName}</b> vill låna <b>{suggestion.itemName}</b>,
          kan du låna ut? <Emoji symbol="😊" />
        </p>
      ) : null}
    </>
  );
};

const AddItemModal = props => {
  const titleText = [
    props.isEditing ? 'Ändra sak' : 'Lägg till sak',
    props.requested ? 'du söker' : 'för utlåning',
  ].join(' ');

  return (
    <SimpleButtonModal
      className="add-item-modal"
      buttonText={props.buttonText || titleText}
      outline={props.outline}
      color={props.color || 'primary'}
      title={titleText}
      {...props}
    >
      <AddItemPage {...props} />
    </SimpleButtonModal>
  );
};

export default AddItemModal;
