import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ProfilePicture from './ProfilePicture';

import { profileToPath } from '../helpers';
import * as routes from '../constants/routes';

const ProfileLink = ({ profile, radius }) =>
  !!profile ? (
    <Link
      to={routes.PROFILE + '/' + profileToPath(profile || {})}
      title={profile.name || ''}
    >
      <ProfilePicture
        profile={profile}
        imageId={profile.image_id}
        radius={radius}
      />
    </Link>
  ) : (
    <ProfilePicture
      profile={profile}
      imageId="profile/undefined"
      radius={radius}
    />
  );

ProfilePicture.propTypes = {
  image_id: PropTypes.string,
  radius: PropTypes.number,
};

export default ProfileLink;
