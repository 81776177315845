import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from 'reactstrap';
import * as routes from '../constants/routes';
import Emoji from './Emoji';
import Loading from './Loading';
import ProfilePicture from './ProfilePicture';
// import QuickLoanModal from './QuickLoanModal';
import StartPage from './StartPage';

const Header = ({ isLoggedIn, myUserProfile, isLoggingIn }) => (
  <div className="header mb-4">
    <Navbar color="green" className="d-inline-block w-100 pb-0">
      <NavbarBrand
        className="float-left py-0 mr-0 font-borrowit header-logo"
        tag={Link}
        to={routes.BORROW}
      >
        <span className="font-borrowit header-logo d-sm-none">B</span>
        <span className="font-borrowit  header-logo d-none d-sm-block">{`Let's Borrowit!`}</span>
      </NavbarBrand>
      {isLoggingIn === true ? (
        <div></div>
      ) : isLoggedIn === false ? (
        <div />
      ) : myUserProfile.isLoading === true ? (
        <Loading />
      ) : (
        <div className="float-right d-inline-flex">
          <Link className="header-link pt-2 px-2" to={routes.BORROW}>
            <Switch>
              <Route
                exact
                path={routes.LANDING}
                render={() => <u>Utlånas</u>}
              />
              <Route exact path={routes.BORROW} render={() => <u>Utlånas</u>} />
              <Route path="*" render={() => 'Utlånas'} />
            </Switch>
          </Link>
          <Link className="header-link pt-2 px-2" to={routes.LEND}>
            <Switch>
              <Route exact path={routes.LEND} render={() => <u>Sökes</u>} />
              <Route path="*" render={() => 'Sökes'} />
            </Switch>
          </Link>

          <Nav navbar className="pt-2">
            <UncontrolledDropdown>
              <DropdownToggle
                nav
                caret
                className="d-none d-sm-inline-block float-right header-link pt-0 pl-2 pr-2"
              >
                <Emoji symbol="🔔" label="" />
              </DropdownToggle>
              <DropdownMenu className="header-notification-feed">
                <StartPage dropdown />
              </DropdownMenu>
              <Link
                className="d-sm-none header-link pt-2 px-2"
                to={routes.START}
              >
                <Emoji symbol="🔔" label="" />
              </Link>
            </UncontrolledDropdown>
          </Nav>

          {/* Profile link */}
          <Nav navbar>
            <Link className="header-link pl-2 d-flex" to={routes.PROFILE}>
              <ProfilePicture
                imageId={myUserProfile.image_id}
                className="d-inline-flex"
              />
              <Switch>
                <Route
                  exact
                  path={routes.PROFILE}
                  render={() => (
                    <u className="align-self-center">
                      <span className="d-none d-lg-inline pl-2">
                        {myUserProfile.short_name}
                      </span>
                    </u>
                  )}
                />
                <Route
                  path="*"
                  render={() => (
                    <span className="d-none d-lg-inline pl-2 align-self-center">
                      {myUserProfile.short_name}
                    </span>
                  )}
                />
              </Switch>
            </Link>
          </Nav>
        </div>
      )}
    </Navbar>
  </div>
);

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
  myUserProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isLoggingIn: state.auth.isLoggingIn,
    myUserProfile: state.myUserProfile,
  };
};

export default withRouter(connect(mapStateToProps)(Header));
