import { format } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { registerLoanAsync } from '../reducers';
import DatePicker from './DatePicker';
import Emoji from './Emoji';
import FriendList from './FriendList';
import SimpleButtonModal from './SimpleButtonModal';

const OutloanForm = ({ item, user, onSuccessFormSubmit, hideModal }) => {
  const [borrower, setBorrower] = useState({});
  const [untilDate, setUntilDate] = useState(new Date());

  const dispatch = useDispatch();

  const isValidInput = () => !!borrower._id && !!untilDate;

  const submitForm = event => {
    event.preventDefault();

    if (isValidInput()) {
      dispatch(
        registerLoanAsync({
          itemId: item._id,
          ownerId: user._id,
          borrowerId: borrower._id,
          untilDate: untilDate,
        })
      );
      hideModal();
    } else {
    }
  };

  return (
    <Form onSubmit={submitForm}>
      <FormGroup>
        <Label>Vem lånar?</Label>
        <FriendList onClick={setBorrower} />
        <Label>Hur länge?</Label>
        <DatePicker onChange={setUntilDate} />

        {isValidInput() ? (
          <>
            <p className="text-center">
              Du registrerar att <b>{borrower.username || 'någon'}</b> lånar{' '}
              <b>{item.name}</b> fram till{' '}
              <b>{format(untilDate, 'dd MMM yyyy')}</b>.{' '}
              {borrower.short_name || 'Lånaren'} får en påminnelse när
              tidsperioden närmar sig sitt slut!
              <Emoji symbol="💆‍" />
            </p>
            <p className="text-center">
              <Button color="primary">
                <Emoji symbol="👍" /> Låna ut
              </Button>
            </p>
          </>
        ) : (
          <p className="text-center">
            Fyll i till vem och hur länge du vill låna ut <b>{item.name}</b>{' '}
            <Emoji symbol="🤗" />
          </p>
        )}
      </FormGroup>
    </Form>
  );
};

const OutloanModal = props => {
  return (
    <SimpleButtonModal title="Registrera utlåning" {...props}>
      <OutloanForm {...props} />
    </SimpleButtonModal>
  );
};

export default OutloanModal;
