import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import AddItemModal from './AddItemModal';
import ItemList from './ItemList';
import Loading from './Loading';
import { profileToPath } from '../helpers';
import ProfilePicture from './ProfilePicture';
import ProfileLink from './ProfileLink';
import TabLayout from './TabLayout';
import Tab from './Tab';
import KarmaPoints from './KarmaPoints';
import Emoji from './Emoji';
import PopoverButton from './PopoverButton';
import QuickLoanModal from './QuickLoanModal';
import ShareButton from './ShareButton';
import * as routes from '../constants/routes';

// const getProfilePronoun = profile =>
//   profile.isMyProfile
//     ? 'du'
//     : profile.gender === 'male'
//     ? 'han'
//     : profile.gender === 'female'
//     ? 'hon'
//     : 'hen';

const GenderEmoji = (props) => {
  return props.gender === 'male' ? (
    <Emoji symbol=" 🕺🏼" label="man" />
  ) : props.gender === 'female' ? (
    <Emoji symbol=" 💃" label="woman" />
  ) : (
    <Emoji symbol=" 😎" label="person" />
  );
};

const UserProfile = ({ match }) => {
  const pathUserProfile = useSelector((state) =>
    Object.values(state.items.userMap).find(
      (user) => profileToPath(user) === match.params.user || ''
    )
  );

  const allItems = useSelector((state) => Object.values(state.items.itemMap));
  const userMap = useSelector((state) => state.items.userMap);

  const myUserProfile = useSelector((state) => state.myUserProfile);

  if (useSelector((state) => state.items.isLoading)) return <Loading />;

  const userProfile = pathUserProfile || myUserProfile;
  const isMyProfile = userProfile._id === myUserProfile._id;
  // const proNoun = getProfilePronoun(userProfile);
  const locationName = (userProfile.location_name || '').split(',')[0];
  const borrowedItems = allItems.filter(
    (i) => !!i.currentLoan && i.currentLoan.borrowerId === userProfile._id
  );
  const ownedItems = allItems
    .filter((i) => i.owner === userProfile._id)
    .filter((i) => !i.requested);
  const requestedItems = allItems
    .filter((i) => i.owner === userProfile._id)
    .filter((i) => i.requested);

  const sharedFriends = (userProfile.friends_list || [])
    .map((id) => userMap[id])
    .filter((a) => a !== undefined);

  return (
    <>
      <div className="mb-3 d-flex flex-wrap">
        <ProfilePicture
          imageId={userProfile.image_id}
          radius={80}
          className="d-none d-sm-block"
        />
        <div className="ml-2 p-2 flex-grow-1">
          <ProfilePicture
            imageId={userProfile.image_id}
            radius={40}
            className="d-sm-none mr-2"
          />
          <div className="d-flex">
            <h3 className="mt-2 d-flex flex-grow-1">
              <span className="d-none d-sm-block">{userProfile.username} </span>
              <span className="d-sm-none">{userProfile.short_name}</span>
              <span className="ml-2">
                <GenderEmoji gender={userProfile.gender} />
              </span>
            </h3>
            {isMyProfile && (
              <Link
                className="mt-2"
                style={{ color: '#333333' }}
                to={routes.SETTINGS}
              >
                <Emoji symbol="⚙️" />
                <span className="d-none d-sm-inline">Inställningar</span>
              </Link>
            )}
          </div>

          <div className="d-flex">
            <KarmaPoints karmaScore={userProfile.karmaScore} />
            <PopoverButton
              buttonText="?"
              popoverHeaderText="Karmapoäng 💪"
              popoverText="Det här är dina karmapoäng! Ju mer du bidrar och lånar - desto mer karma får du. Såklart."
            />
          </div>
          {locationName !== '' && (
            <p>
              <Emoji symbol="🌍" label="jordglob" />
              {locationName}
            </p>
          )}
          <p>
            <Emoji symbol="😎" />
            {ownedItems.length} saker för utlåning
          </p>
          <p>
            <Emoji symbol="🤗" />
            {sharedFriends.length} vänner
          </p>
          <QuickLoanModal buttonText="💡Registrera lån" color="primary" />
        </div>

        <div className="mt-4 ml-sm-5 pv-2">
          <div className="ml-sm-5">
            {isMyProfile && (
              <p>
                Vänners låneprofiler <Emoji symbol="👇" />
              </p>
            )}
            {!isMyProfile && (
              <p>
                <Emoji symbol="🤩" />
                {sharedFriends.length} gemensamma vänner
              </p>
            )}
            <div className="mt-3 d-flex flex-wrap">
              {sharedFriends.map((friend) => (
                <ProfileLink key={friend.facebook_id} profile={friend} />
              ))}
            </div>

            <div className="my-2">
              <ShareButton buttonText="Bjud in fler vänner" />
            </div>
          </div>
        </div>
      </div>

      <Container className="my-4 p-0">
        <TabLayout>
          <Tab title={'Lånar ut'}>
            {ownedItems.length < 1 ? (
              <div className="text-center">
                {isMyProfile ? (
                  <div>
                    <div className="p-3">
                      <strong>
                        Lägg till dina första saker för att komma igång{' '}
                        <Emoji symbol="💁" />‍
                      </strong>
                    </div>
                    {isMyProfile ? <AddItemModal /> : null}
                  </div>
                ) : (
                  <div className="p-3">
                    <strong>
                      {userProfile.first_name} har inte lagt till några saker än{' '}
                      <Emoji symbol="😞" />‍
                    </strong>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isMyProfile && (
                  <div className="d-flex">
                    <AddItemModal className="ml-auto mb-3" />
                  </div>
                )}
                <ItemList items={ownedItems} showTown />
              </div>
            )}
          </Tab>
          <Tab title={'Söker'}>
            {requestedItems.length < 1 ? (
              <div className="text-center">
                {isMyProfile ? (
                  <div>
                    <div className="p-3">
                      <strong>
                        Låt dina vänner veta vad du vill låna{' '}
                        <Emoji symbol="🙋‍♀️" />
                      </strong>
                    </div>
                    {isMyProfile && <AddItemModal requested />}
                  </div>
                ) : (
                  <div className="p-3">
                    <strong>
                      {userProfile.first_name} vill inte låna något just nu{' '}
                      <Emoji symbol="🤷‍♂️" />‍
                    </strong>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isMyProfile && (
                  <div className="d-flex">
                    <AddItemModal requested className="ml-auto mb-3" />
                  </div>
                )}
                <ItemList items={requestedItems} />
              </div>
            )}
          </Tab>
          <Tab title="Lånar">
            {borrowedItems.length < 1 ? (
              <div className="text-center p-3">
                <strong>
                  Saker {isMyProfile ? 'du' : userProfile.short_name} lånar
                  kommer att visas här <Emoji symbol="🎉" />‍
                </strong>
              </div>
            ) : (
              <div>
                {!isMyProfile && (
                  <div className="text-center mb-4">
                    Bara saker som lånas av era gemensamma vänner visas{' '}
                    <Emoji symbol="🙂" />
                  </div>
                )}
                <ItemList items={borrowedItems} />
              </div>
            )}
          </Tab>
        </TabLayout>
      </Container>
    </>
  );
};

/*
const mapStateToProps = (state, ownProps) => {
  let userProfile = (state.items.userFriends || []).find(
    user => profileToPath(user) === ownProps.match.params.user || ''
  );
  userProfile = !userProfile
    ? { ...state.myUserProfile, isMyProfile: true }
    : { ...userProfile, isMyProfile: false };

  return {
    ...userProfile,
    isLoading: state.items.isLoading,
    userProfile,
    proNoun: userProfile.isMyProfile
      ? 'du'
      : userProfile.gender === 'male'
      ? 'han'
      : userProfile.gender === 'female'
      ? 'hon'
      : 'hen',
    borrowedItems: Object.values(state.items.itemMap)
      .concat(
        state.myUserProfile.items.map(item => ({
          user: state.myUserProfile,
          item: { ...item, isRemovable: true },
        }))
      )
      .filter(
        ui =>
          !!ui.item.currentLoan &&
          ui.item.currentLoan.borrowerId === userProfile._id
      )
      .sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt)),
    ownedItems: (userProfile.items || [])
      .filter(item => !item.requested)
      .sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))
      .map(item => {
        return {
          item: { ...item, isRemovable: userProfile.isMyProfile },
          user: userProfile,
        };
      }),
    requestedItems: (userProfile.items || [])
      .filter(item => item.requested)
      .sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))
      .map(item => {
        return {
          item: { ...item, isRemovable: userProfile.isMyProfile },
          user: userProfile,
        };
      }),
    // myFriendsWithThingsToBorrow: state.items.ownedItems
    //   .map(userItem => userItem.user)
    //   .filter((value, index, self) => self.indexOf(value) === index),
    // friendsCount: userProfile.friends.length,
    sharedFriends: userProfile.friends
      .map(friend =>
        (state.items.userFriends || []).find(
          user => friend._id === user.facebook_id
        )
      )
      .filter(a => a !== undefined),
    locationName: (userProfile.location_name || '').split(',')[0],
  };
};
*/

export default UserProfile;
