import React from 'react';
import PropTypes from 'prop-types';
import { FacebookProvider, Share } from 'react-facebook';
import { Button } from 'reactstrap';

const ShareButton = ({ buttonText = 'Bjud in vänner' }) => (
  <FacebookProvider appId="339058976578312">
    <Share href="http://www.letsborrowit.com">
      {({ handleClick, loading }) => (
        <Button
          color="secondary"
          disabled={loading}
          onClick={handleClick}
          className="mt-3"
        >
          {buttonText}
        </Button>
      )}
    </Share>
  </FacebookProvider>
);

ShareButton.propTypes = {
  buttonText: PropTypes.string,
};

export default ShareButton;
