import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Emoji from './Emoji';
import ProfileLink from './ProfileLink';
import { Link, withRouter } from 'react-router-dom';
import * as routes from '../constants/routes';

const niklas = {
  name: 'Niklas Lavrell',
  image_id: 'profile/10155832724347012',
};

const hanna = {
  name: 'Hanna Söderquist',
  image_id: 'profile/10155232692780812',
};

const jacob = {
  name: 'Jacob Svensson',
  image_id: 'profile/10155053456321433',
};

const AboutText = () => (
  <span>
    Vi är tre delningsälskare och hobbyminimalister som tror att både du och
    planeten kommer må lite bättre om vi köper mindre och lånar mer! Därför har
    vi fixat ett ställe där du kan låna och låna ut grejer tillsammans med dina
    vänner.
    <br />
    <br />
    Hör gärna av dig om du har feedback på sajten, vill samarbeta eller bara
    vill säga hej! <Emoji symbol="☀️" />
    <br />
    <br />
    Du når oss enklast på{' '}
    <a
      href="mailto:hi@letsborrowit.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      hi@letsborrowit.com
    </a>
    .
  </span>
);

const About = ({ isLoggedIn }) => (
  <>
    {!isLoggedIn && (
      <Row className="header mr-0">
        <Col color="green" className="d-inline-block w-100 pb-0">
          <Link
            className="float-left ml-3 font-borrowit header-logo py-2"
            tag={Link}
            to={routes.LANDING}
          >
            <span className="font-borrowit  header-logo">{`Let's Borrowit!`}</span>
          </Link>
        </Col>
      </Row>
    )}

    <h1 className="mb-3 mt-5 text-center my-4">Hej hej!</h1>
    {isLoggedIn ? (
      <>
        <div className="m-auto py-4 d-flex text-center my-5 justify-content-center">
          <span className="px-3">
            <ProfileLink profile={niklas} radius={80} />
          </span>
          <span className="px-3">
            <ProfileLink profile={jacob} radius={80} />
          </span>
          <span className="px-3">
            <ProfileLink profile={hanna} radius={80} />
          </span>
        </div>
        <div
          className="mt-5 pt-3 px-3 m-auto text-center"
          style={{ maxWidth: '60rem' }}
        >
          <AboutText />
        </div>
      </>
    ) : (
      <div className="d-flex flex-wrap justify-content-center">
        <img
          src="/aboutus.jpg"
          alt="Gänget"
          style={{
            maxWidth: '100%',
            width: '30rem',
            padding: '1rem',
          }}
        />
        <div className="mt-4 px-3" style={{ maxWidth: '30rem' }}>
          <AboutText />
        </div>
      </div>
    )}
    <div className="d-flex pt-5 justify-content-center">
      <a
        href="https://www.facebook.com/letsborrowit"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/fb.svg"
          alt="Gilla oss på Facebook!"
          className="px-2 "
          style={{
            width: '4.5rem',
          }}
        />
      </a>
      <a
        href="https://www.instagram.com/letsborrowit"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/insta.svg"
          alt="Följ oss på Instagram!"
          className="px-2 "
          style={{
            width: '4.5rem',
          }}
        />
      </a>
    </div>
  </>
);

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default withRouter(connect(mapStateToProps)(About));
