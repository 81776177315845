import React from 'react';
import { connect } from 'react-redux';
import { Row, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import Emoji from './Emoji';

import { itemToPath, profileToPath } from '../helpers';
import * as routes from '../constants/routes';

const datefns = require('date-fns');

const FeedBox = ({
  imageId,
  text,
  subject,
  timeStamp,
  users,
  items,
  emoji,
}) => {
  const daysSinceTime = datefns.differenceInCalendarDays(
    Date.now(),
    Date.parse(timeStamp)
  );
  const daysSinceText =
    daysSinceTime === 0
      ? 'Idag'
      : daysSinceTime === 1
      ? 'Igår'
      : daysSinceTime + ' dagar sedan';

  return (
    <div>
      <hr />
      <Row className="feed-row">
        <div style={{ marginLeft: '15px' }}>
          {emoji ? (
            <div style={{ float: 'left', maxWidth: '60px', width: '15%' }}>
              <span
                style={{
                  display: 'inline-block',
                  borderRadius: '50%',
                  padding: 0,
                  lineHeight: '60px',
                  width: '60px',
                  fontSize: '30px',
                  height: '60px',
                  textAlign: 'center',
                  background: '#fdf5e9',
                }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    marginTop: '4px',
                  }}
                >
                  {emoji}
                </span>
              </span>{' '}
            </div>
          ) : (
            /*
<div style='float: left; max-width: 60px; width: 15%;'>
              <span style='
              display:inline-block;
              border-radius: 50%;
              padding: 0;
              line-height: 60px;
              width: 60px;
              font-size: 30px;
              height: 60px;
              text-align: center;
              background: #fdf5e9'><span style='
              display:inline-block;
              margin-top:4px;'>{emoji}</span></span>
            </div>
            */

            <img
              alt=""
              src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_limit,h_60,r_5,w_60/v1/${imageId}.png`}
            />
          )}
        </div>
        <div className="p-2">
          <strong>{subject}</strong>
          <br className="d-sm-none" />
          <small style={{ color: 'gray' }}> {daysSinceText} </small>
          <br />
          <span style={{ fontSize: '14px' }}>
            {text.split(/\[|\]/).map(subText => {
              const a = subText.match(/user:(.*)/);
              const b = subText.match(/item:(.*)/);
              const link = subText.match(/link:(.*):(.*)/);

              if (a) {
                const profile = users[a[1]];
                return (
                  <Link
                    key={profile._id}
                    to={routes.PROFILE + '/' + profileToPath(profile || {})}
                    title={profile.name || ''}
                  >
                    {profile.name.split(' ')[0]}
                  </Link>
                );
              } else if (b) {
                const item = items[b[1]];
                return (
                  <React.Fragment key={item._id}>
                    <Link
                      title={item.name || ''}
                      className="d-sm-none"
                      to={routes.ITEM + '/' + itemToPath(item || {})}
                    >
                      {item.name}
                    </Link>
                    <Link
                      title={item.name || ''}
                      className="d-none d-sm-inline"
                      to={{
                        pathname: routes.ITEM + '/' + itemToPath(item || {}),
                        state: { modal: true },
                      }}
                    >
                      {item.name}
                    </Link>
                  </React.Fragment>
                );
              } else if (link) {
                return (
                  <Link
                    key={link[2]}
                    to={link[2]}
                    title={'Länk till ' + link[1]}
                  >
                    {link[1]}
                  </Link>
                );
              } else {
                return subText;
              }
            })}
          </span>
        </div>
      </Row>
    </div>
  );
};

class StartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewsAmount: 0,
    };
    this.increaseNewsAmount = this.increaseNewsAmount.bind(this);
  }

  increaseNewsAmount() {
    if (this.props.showBuckets.length > this.state.showNewsAmount + 1) {
      this.setState({ showNewsAmount: this.state.showNewsAmount + 1 });
    }
  }

  render() {
    return (
      <div>
        <Container
          style={{
            maxWidth: '500px',
            minWidth: this.props.dropdown ? '500px' : '',
          }}
          className="my-2"
        >
          <h4>
            Hänt på Let's Borrowit <Emoji symbol="👇" />
          </h4>
          {this.props.events
            .filter(
              event =>
                event.timeBucket <=
                this.props.showBuckets[this.state.showNewsAmount]
            )
            .map(event => (
              <FeedBox
                {...event}
                key={event.timeStamp}
                users={this.props.users}
                items={this.props.items}
              />
            ))}
          <hr />
          <div className="text-center">
            <Button
              outline
              color="primary"
              size="sm"
              disabled={this.props.events.length <= this.state.showNewsAmount}
              onClick={() => this.increaseNewsAmount()}
            >
              Visa tidigare händelser
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const events = (state.feed.events || []).sort(
    (event1, event2) =>
      Date.parse(event2.timeStamp) - Date.parse(event1.timeStamp)
  );

  const buckets = [
    ...events.map(event => event.timeBucket),
    Number.MAX_SAFE_INTEGER,
    Number.MAX_SAFE_INTEGER,
  ]
    .filter((value, index, Arr) => index !== 0)
    .filter((value, index, Arr) => index % 3 === 0)
    .filter((elem, index, self) => index === self.indexOf(elem));

  return {
    ...state.feed,
    events: events,
    showBuckets: buckets,
    isLoading: state.feed.isLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StartPage);
