import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { editItemAsync } from '../reducers';
import Emoji from './Emoji';
import ProfilePicture from './ProfilePicture';

class GiveAway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      modal: false,
      invalidInput: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onRbClick = this.onRbClick.bind(this);
    this.isRbActive = this.isRbActive.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.modalview('/item/regular/lend');
    }
    this.setState({ modal: !this.state.modal });
  }

  onRbClick(group, value) {
    const a = {};
    a[group] = value;
    this.setState(a);
  }

  isRbActive(group, value) {
    return this.state[group] === value;
  }

  getRbActive(group) {
    return this.state[group];
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    if (event.target.value !== '') {
      this.setState({
        invalidInput: false,
      });
    }
  }

  render() {
    const titleText = 'Skänk ' + this.props.item.name;
    const buttonText = this.props.buttonText || titleText;
    return (
      <>
        <Button
          color="link"
          className={this.props.className}
          onClick={this.toggle}
        >
          {buttonText}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{titleText}</ModalHeader>
          <Form
            onSubmit={event => {
              if (this.state.value === '') {
                this.setState({ invalidInput: true });
                event.preventDefault();
              } else {
                this.setState({ value: '' });
                this.props.addItemAsync(event, {
                  name: this.state.value,
                  requested: this.props.requested,
                });
              }
            }}
          >
            <ModalBody>
              <FormGroup>
                <div>
                  <Label for="exampleText">Vem vill du skänka till?</Label>
                  <br />
                  <ButtonToolbar>
                    {this.props.myFriends.map(friend => {
                      return (
                        <Button
                          key={friend._id}
                          outline
                          color="primary"
                          onClick={() => this.onRbClick('friend', friend)}
                          active={this.isRbActive('friend', friend)}
                        >
                          <ProfilePicture imageId={friend.image_id} />
                        </Button>
                      );
                    })}
                  </ButtonToolbar>

                  <br />
                </div>
              </FormGroup>

              <br />

              {this.getRbActive('friend') !== undefined ? (
                <React.Fragment>
                  <p className="text-center">
                    Du registrerar att{' '}
                    <b>{this.getRbActive('friend').username || 'någon'}</b> blir
                    ägare och sköter utlåningen framöver! <Emoji symbol="🥰" />{' '}
                    För enkelhetens skull nollställer vi eventuella lån.
                  </p>
                  <p className="text-center">
                    <Button
                      color="primary"
                      onClick={() =>
                        this.props.editItemAsync({
                          ...this.props.item,
                          owner: this.getRbActive('friend'),
                          currentLoan: null,
                        })
                      }
                    >
                      <Emoji symbol="👍" /> Skänk bort
                    </Button>
                  </p>
                </React.Fragment>
              ) : (
                <p className="text-center">
                  Fyll i vem du vill skänka bort {this.props.item.name} till{' '}
                  <Emoji symbol="🤗" />
                </p>
              )}
            </ModalBody>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    myFriends: Object.values(state.items.userMap),
    requesteduseritems: Object.values(state.items.itemMap).filter(
      item => item.requested
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    editItemAsync: item => {
      return dispatch(editItemAsync(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiveAway);
