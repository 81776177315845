import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import BorrowIt from './components/BorrowIt';
import store, { history } from './store/index.js';
import registerServiceWorker from './registerServiceWorker';

import './css/bootstrap.css';
import './css/index.css';
import './css/App.css';

// GA TRACKER Production: UA-117510052-2
// GA TRACKER Test/Dev: UA-117510052-1
ReactGA.initialize(process.env.REACT_APP_GA_TRACKER || 'UA-117510052-1');

const tagManagerArgs = { gtmId: 'GTM-WC4VBBD' };
TagManager.initialize(tagManagerArgs);

history.listen((location, action) => {
  // TODO: should probably log a google tag manager pageview here as well
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BorrowIt />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
