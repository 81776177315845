import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { ButtonGroup, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import Emoji from './Emoji';
import SuggestItem from './SuggestItem';

class WelcomeModal extends Component {
  constructor(props) {
    super(props);
    // localStorage.setItem('seenWelcomeModal', true)
    this.state = {
      modal: this.props.isFirstLogin,
      activeTab: 1,
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.state.modal) {
      ReactGA.modalview('/welcome/' + this.state.activeTab);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.modalview('/welcome/' + this.state.activeTab);
    }
    this.setState({
      modal: !this.state.modal,
      activeTab: 1,
    });
  }

  handleChange(tab) {
    tab = tab < 1 ? 1 : tab;
    if (this.state.activeTab !== tab) {
      ReactGA.modalview('/welcome/' + tab);
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    // TODO: would {this.state.modal ? <Modal> : ''} be more performant?
    return (
      <div>
        <Modal
          backdrop="static"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className + ' welcome-modal'}
        >
          <ModalHeader
            style={{ backgroundColor: 'primary' }}
            toggle={this.toggle}
          >
            {`Välkommen till Let's Borrowit`}
          </ModalHeader>
          <ModalBody>
            <div className="modal-image" />

            <div style={{ minHeight: '165px' }}>
              <div className={this.state.activeTab !== 1 ? 'd-none' : ''}>
                <h3 className="text-center mt-3">
                  Här är du bland vänner <Emoji symbol="🎉" />
                </h3>
                <p className="text-center">
                  {`På Let's Borrowit kan du och dina vänner låna saker av varandra - så att ni slipper köpa nytt i onödan!`}
                </p>
                <p className="text-center font-weight-bold">
                  Klicka dig vidare för en snabbguide!
                </p>
              </div>

              <div className={this.state.activeTab !== 2 ? 'd-none' : ''}>
                <h3 className="text-center mt-3">
                  Sök efter saker <Emoji symbol="🔍" />
                </h3>
                <p className="text-center">
                  {`Hitta det du vill låna genom att söka bland dina vänners saker. Ni sköter sen kontakten på det sätt som ni vanligtvis gör. När du lånar en sak på Let's Borrowit får du en påminnelse när det är dags för återlämning!`}
                </p>
              </div>

              <div className={this.state.activeTab !== 3 ? 'd-none' : ''}>
                <h3 className="text-center mt-3">
                  Lägg upp det du söker <Emoji symbol="🙏" />
                </h3>
                <p className="text-center">
                  Du kan också låta dina vänner veta vad du vill låna genom att
                  lägga upp det! På samma sätt kan du kolla vad dina vänner vill
                  låna och låna ut det till dem.
                </p>
              </div>

              <div className={this.state.activeTab !== 4 ? 'd-none' : ''}>
                <h3 className="text-center mt-3">
                  Låna ut till vänner <Emoji symbol="💁‍" />
                </h3>
                <p className="text-center">
                  Lägg upp saker du kan tänka dig att låna ut till dina vänner.
                  Få koll på det du lånar ut, till vem och hur länge.
                </p>
              </div>
            </div>

            <div>
              {this.state.activeTab === 1 ? (
                <Button className="float-left" color="outline disabled">
                  Bakåt
                </Button>
              ) : (
                <Button
                  className="float-left"
                  color="outline"
                  onClick={() => this.handleChange(this.state.activeTab - 1)}
                >
                  Bakåt
                </Button>
              )}

              {this.state.activeTab === 4 ? (
                <>
                  <SuggestItem
                    showWithButton
                    className="d-inline float-right"
                    onModalClose={this.toggle}
                  />
                </>
              ) : (
                <Button
                  className="float-right"
                  color="primary"
                  onClick={() => this.handleChange(this.state.activeTab + 1)}
                >
                  Nästa
                </Button>
              )}

              <ButtonGroup
                className="mx-auto d-block"
                style={{ width: '100px' }}
              >
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  className={this.state.activeTab === 1 ? 'active' : ''}
                  style={{
                    border: '0px',
                    color: this.state.activeTab === 1 ? 'white' : '',
                  }}
                  onClick={() => this.handleChange(1)}
                >
                  1
                </Button>
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  className={this.state.activeTab === 2 ? 'active' : ''}
                  style={{
                    border: '0px',
                    color: this.state.activeTab === 2 ? 'white' : '',
                  }}
                  onClick={() => this.handleChange(2)}
                >
                  2
                </Button>
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  className={this.state.activeTab === 3 ? 'active' : ''}
                  style={{
                    border: '0px',
                    color: this.state.activeTab === 3 ? 'white' : '',
                  }}
                  onClick={() => this.handleChange(3)}
                >
                  3
                </Button>
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  className={this.state.activeTab === 4 ? 'active' : ''}
                  style={{
                    border: '0px',
                    color: this.state.activeTab === 4 ? 'white' : '',
                  }}
                  onClick={() => this.handleChange(4)}
                >
                  4
                </Button>
              </ButtonGroup>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFirstLogin: state.myUserProfile.first_login,
});

WelcomeModal.propTypes = {
  className: PropTypes.string,
  isFirstLogin: PropTypes.string,
};

export default connect(mapStateToProps)(WelcomeModal);
