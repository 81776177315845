import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { fetchPopularItemsAsync } from '../reducers';

class ExploreTags extends React.Component {
  componentDidMount() {
    this.props.fetchPopularItemsAsync();
  }

  render() {
    return (
      <div className="mb-2">
        {this.props.popularItems &&
          this.props.popularItems.slice(0, 11).map((item, index) => (
            <Button
              key={item.name}
              color="secondary"
              className={
                (index > 10 ? 'd-none d-md-inline-block' : '') ||
                (index > 7 ? 'd-none d-sm-inline-block' : '') + ' nohover'
              }
              outline={this.props.searchValue !== item.name}
              style={{
                fontSize: '10px',
                margin: '5px',
                padding: '3px',
                color: '#212529',
                borderColor: '#ced4da',
              }}
              onClick={() => this.props.onClick({ searchValue: item.name })}
            >
              {item.name}
            </Button>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    popularItems: state.extra.popularItems,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPopularItemsAsync: () => {
      dispatch(fetchPopularItemsAsync());
    },
  };
};

ExploreTags.propTypes = {
  fetchPopularItemsAsync: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  popularItems: PropTypes.array,
  searchValue: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreTags);
