import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { setProfileSettingAsync } from '../reducers';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';
import Emoji from './Emoji';
//import { useLocalStorage } from '../hooks';

const Settings = () => {
  // const [localBeta, setLocalBeta] = useLocalStorage('beta', 'false');

  const profileSettings = useSelector(
    (store) => store.myUserProfile.settings || {}
  );

  const dispatch = useDispatch();

  return (
    <div className="mt-5">
      <h2>Inställningar</h2>
      <Row>
        <Col>
          <div className="btn-toolbar mt-4">
            <span className="mr-5 pr-5">
              {/* Beta-funktioner för den här användaren */}
              <h5>Visa mig nya spännande beta-funktioner</h5>
              <p>Så att du blir först att testa nya roligheter!</p>
            </span>
            <span>
              {booleanToggle(profileSettings.beta_tester, (value) =>
                dispatch(setProfileSettingAsync('beta_tester', value))
              )}
            </span>
          </div>
          <div className="btn-toolbar mt-5">
            <span className="mr-4 pr-4">
              <h5>Maila mig ibland om aktivitet på sajten</h5>
              <p>Så att du inte missar något du eller dina vänner vill låna!</p>
            </span>
            <span>
              {booleanToggle(
                !profileSettings.unsubscribed_from_emails,
                (value) =>
                  dispatch(
                    setProfileSettingAsync('unsubscribed_from_emails', !value)
                  )
              )}
            </span>
          </div>
          <div className="mt-5">
            <Link style={{ color: '#333333' }} to={routes.LOGOUT}>
              <Emoji symbol="🚶‍♂️" label="walk out" /> Logga ut
            </Link>
          </div>
          {/* <p>
            Beta-funktioner på denna enhet:
            {booleanToggle(localBeta, setLocalBeta)}
          </p> */}

          {/* <p>
            Varningar när användar-information saknas:
            {booleanToggle(
              !profileSettings.ignore_missing_fb_permissions,
              value =>
                dispatch(
                  setProfileSettingAsync(
                    'ignore_missing_fb_permissions',
                    !value
                  )
                )
            )}
          </p> */}

          {/*
          <p>
            Nyhetsbrev skickas:
            <FormGroup className="d-inline mb-1">
              <Input
                type="select"
                name="days_between_activity_mail"
                bsSize="sm"
                value={profileSettings.days_between_activity_mail}
                className="d-inline mx-1 mb-1"
                style={{ width: '165px' }}
                onChange={event =>
                  dispatch(
                    setProfileSettingAsync(
                      'days_between_activity_mail',
                      parseInt(event.target.value)
                    )
                  )
                }
              >
                {Object.entries({
                  1: 'Varje dag',
                  7: 'Varje vecka',
                  14: 'Varannan vecka',
                  30: 'Varje månad',
                }).map(a => (
                  <option key={a[1]} value={a[0]}>
                    {a[1]}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </p>
          */}
        </Col>
      </Row>
    </div>
  );
};

function booleanToggle(value, toggle) {
  return (
    <>
      {' '}
      <ButtonGroup>
        <Button
          color={value ? 'light' : 'dark'}
          size="sm"
          disabled={!value}
          onClick={() => toggle(!value)}
        >
          Av
        </Button>
        <Button
          color={value ? 'primary' : 'light'}
          size="sm"
          onClick={() => toggle(!value)}
        >
          På
        </Button>
      </ButtonGroup>
    </>
  );
}

export default Settings;
