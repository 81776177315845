import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookProvider, CustomChat } from 'react-facebook';
import ReactGA from 'react-ga';
import { Container } from 'reactstrap';

import Explore from './Explore';
import ExploreRequests from './ExploreRequests';
import Footer from './Footer';
import Header from './Header';
import Home from './Home';
import ItemModal from './ItemModal';
import ItemView from './ItemView';
import Loading from './Loading';
import Login from './Login';
import Logout from './Logout';
import NotFound from './NotFound';
import PermissionsAlert from './PermissionsAlert';
import Settings from './Settings';
import StartPage from './StartPage';
import SuggestItem from './SuggestItem';
import Terms from './Terms';
import UserProfile from './UserProfile';
import WelcomeModal from './WelcomeModal';
import About from './About';

import { usePrevious } from '../hooks';
import { getAccessTokenFromLocalStorage } from '../reducers';
import * as routes from '../constants/routes';

const triggerPageReactGaEvents = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  if (window.performance) {
    const initialPathName = window.location.pathname;
    window.addEventListener('load', () => {
      const timeSincePageLoad = Math.round(window.performance.now());
      const label =
        initialPathName === window.location.pathname
          ? initialPathName
          : `${initialPathName} -> ${window.location.pathname}`;
      ReactGA.timing({
        category: 'Page Load',
        variable: 'Page Loaded',
        value: timeSincePageLoad,
        label: label,
      });
    });
    window.addEventListener('DOMContentLoaded', () => {
      const timeSincePageLoad = Math.round(window.performance.now());
      const label =
        initialPathName === window.location.pathname
          ? initialPathName
          : `${initialPathName} -> ${window.location.pathname}`;
      ReactGA.timing({
        category: 'Page Load',
        variable: 'Page DOM Loaded',
        value: timeSincePageLoad,
        label: label,
      });
    });
  }
};

const BorrowIt = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    triggerPageReactGaEvents();
  }, []);

  // Load the access token from localstorage to state.
  useEffect(() => {
    dispatch(getAccessTokenFromLocalStorage());
  }, [dispatch]);

  // Has access token or any sign that the user has been logged in before.
  const isPossibleLoggedIn = useSelector(
    (store) =>
      store.auth.fbAccessToken !== undefined && store.auth.fbAccessToken !== ''
  );

  // Has received it's user profile in the react state.
  const isLoggedIn = useSelector(
    (store) => store.myUserProfile._id !== undefined
  );

  // Will show the previous page in the background while rendering a new modal.
  const renderInModal =
    location.state &&
    location.state.modal &&
    previousLocation &&
    previousLocation !== location;

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-grow-1 flex-shrink-0 flex-basis-auto">
        <FacebookProvider appId="339058976578312" language="sv_SE">
          <CustomChat
            pageId="1796752500633963"
            themeColor="#27BC9E"
            loggedInGreeting="👋 Hur kan vi förbättra sajten? Ge oss feedback 🤗"
            loggedOutGreeting="👋 Hur kan vi förbättra sajten? Ge oss feedback 🤗"
          />
        </FacebookProvider>
        {isLoggedIn
          ? renderLoggedInView(renderInModal, location, previousLocation)
          : isPossibleLoggedIn
          ? renderPossibleLoggedInView()
          : renderNotLoggedInView()}
      </div>
      <Footer className="flex-shrink-0" />
    </div>
  );
};

const renderPossibleLoggedInView = () => (
  <>
    <Header />
    <Container>
      <Loading />
    </Container>
  </>
);

const renderNotLoggedInView = () => (
  <Switch>
    <Route exact path={routes.LANDING} component={Home} />
    <Route exact path={routes.ABOUT} component={About} />
    {/*Alway render home to let the user reach the right page when logging in*/}
    <Route component={Home} />
  </Switch>
);

const renderLoggedInView = (renderInModal, location, previousLocation) => (
  <>
    <Header />
    <PermissionsAlert />
    <WelcomeModal />
    <SuggestItem />
    <Container>
      <Switch location={renderInModal ? previousLocation : location}>
        <Route
          exact
          path={routes.LANDING}
          render={() => <Redirect to={routes.BORROW} />}
        />
        <Route exact path={routes.START} component={StartPage} />
        <Route exact path={routes.SETTINGS} component={Settings} />
        <Route exact path={routes.BORROW} component={Explore} />
        <Route exact path={routes.LEND} component={ExploreRequests} />
        <Route exact path={routes.LOGIN} component={Login} />
        <Route exact path={routes.LOGOUT} component={Logout} />
        <Route exact path={routes.PROFILE_USER} component={UserProfile} />
        <Route exact path={routes.PROFILE} component={UserProfile} />
        <Route exact path={routes.TERMS} component={Terms} />
        <Route exact path={routes.ITEM_ID} component={ItemView} />
        <Route exact path={routes.ABOUT} component={About} />
        <Route component={NotFound} />
      </Switch>
      {renderInModal && (
        <Route exact path={routes.ITEM_ID} component={ItemModal} />
      )}
    </Container>
  </>
);

export default BorrowIt;
