import PropTypes from 'prop-types';
import React from 'react';

const maxKarmaPoints = 5;

const KarmaPoints = ({ karmaScore }) => {
  const karmaPoints = parseInt((maxKarmaPoints * (karmaScore || 0)) / 100, 10);

  return (
    <div className="mb-3">
      {Array.apply(null, { length: karmaPoints }).map((a, index) => (
        <img
          src="/karmastar-filled.png"
          alt="Filled star"
          height="20"
          width="20"
          key={'filled-' + index}
        />
      ))}
      {Array.apply(null, { length: maxKarmaPoints - karmaPoints }).map(
        (a, index) => (
          <img
            src="/karmastar-empty.png"
            alt="Empty star"
            height="20"
            width="20"
            key={'empty-' + index}
          />
        )
      )}
    </div>
  );
};

KarmaPoints.propTypes = {
  karmaScore: PropTypes.number.isRequired,
};

export default KarmaPoints;
