import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import ProfilePicture from './ProfilePicture';

class FriendList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      invalidInput: false,
    };
    this.onRbClick = this.onRbClick.bind(this);
    this.isRbActive = this.isRbActive.bind(this);
  }

  onRbClick(group, value) {
    const a = {};
    a[group] = value;
    this.setState(a);
    this.props.onClick(value);
  }

  isRbActive(group, value) {
    return this.state[group] === value;
  }

  getRbActive(group) {
    return this.state[group];
  }

  render() {
    return (
      <ButtonToolbar>
        {this.props.myFriends.map(friend => {
          return (
            <Button
              key={friend.facebook_id}
              outline
              color="primary"
              onClick={() => this.onRbClick('friend', friend)}
              active={this.isRbActive('friend', friend)}
            >
              <ProfilePicture imageId={friend.image_id} />
            </Button>
          );
        })}
      </ButtonToolbar>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    myFriends: state.myUserProfile.friends_list
      .map(id => state.items.userMap[id])
      .filter(a => a !== null),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendList);
