import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login';

import { handleFacebookLogin, facebookLoginClicked } from '../reducers';

/**
 * Login with Facebook button
 *
 * @param {boolean} authTypeRerequest - True if request for permissions should be forced.
 * @param {function} buttonCallback - Callback function.
 * @param {function} buttonClicked - Button clicked function.
 */
const LoginButton = ({ authTypeRerequest, buttonCallback, buttonClicked }) => (
  <FacebookLogin
    appId="339058976578312"
    autoLoad={false}
    fields="id, first_name, last_name, middle_name, name, name_format, picture,
        short_name, email, link, location, cover, locale, timezone, age_range, gender"
    scope="public_profile, email, user_friends, user_location, user_gender, user_link"
    textButton={
      authTypeRerequest ? '👉 Se över rättigheter' : '👉 Fortsätt med Facebook'
    }
    size="small"
    onClick={buttonClicked}
    callback={buttonCallback}
    cssClass="btn btn-primary btn-landing"
    redirectUri="https://www.letsborrowit.com/"
    language="sv_SE"
    cookie
    disableMobileRedirect
    authType={authTypeRerequest ? 'rerequest' : ''}
  />
);

const mapStateToProps = (state, ownProps) => {
  return {
    authTypeRerequest: ownProps.authTypeRerequest,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    buttonCallback: response => {
      dispatch(handleFacebookLogin(response, ownProps.authTypeRerequest));
    },
    buttonClicked: () => {
      dispatch(facebookLoginClicked(ownProps.authTypeRerequest));
    },
  };
};

LoginButton.propTypes = {
  authTypeRerequest: PropTypes.bool,
  buttonCallback: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
