import React from 'react';
import { Col, Row } from 'reactstrap';
import Emoji from './Emoji';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';

const Footer = () => (
  <Row className="w-100 mt-5 mx-0">
    <Col className="footer m-auto text-center p-4 d-flex col">
      <div className="m-auto">
        <div>
          Skapat med <Emoji symbol="‍‍️️💚" label="Kärlek" /> för{' '}
          <Emoji symbol="‍‍️️🧘🏽‍♀️" label="Dig" /> och{' '}
          <Emoji symbol="‍‍️️🌍" label="Planeten" /> av
          <u>
            <Link
              className="pt-1 px-2"
              style={{
                color: 'white',
              }}
              to={routes.ABOUT}
            >
              Hanna, Jacob & Niklas{' '}
            </Link>
          </u>
        </div>
        <div className="pt-4">
          <a
            href="https://www.facebook.com/letsborrowit"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/fb.svg"
              alt="Gilla oss på Facebook!"
              className="px-1 "
              style={{
                width: '4rem',
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/letsborrowit"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/insta.svg"
              alt="Följ oss på Instagram!"
              className="px-1 "
              style={{
                width: '4rem',
              }}
            />
          </a>
        </div>
      </div>
    </Col>
  </Row>
);

export default Footer;
