import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer, { fetchProfileAsync } from '../reducers';
import { addHours } from 'date-fns';

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);

store.subscribe(fetchProfileListener);
store.subscribe(apiVersionListener);

let currentValue = '';
function fetchProfileListener() {
  console.log('CurrentValue', currentValue);
  let previousValue = currentValue;
  currentValue = store.getState().auth.fbAccessToken;
  if (currentValue === '' && previousValue !== currentValue) {
    console.log('Removing accessToken from localStorage');
    localStorage.removeItem('fbAccessToken');
    history.push('/');
  } else if (previousValue !== currentValue) {
    console.log('Setting accessToken in localStorage');
    localStorage.setItem('fbAccessToken', currentValue);
    store.dispatch(fetchProfileAsync());
    // moving these ones into fetchProfileAsync to be able to run them after
    // store.dispatch(fetchItemsAsync())
    // store.dispatch(fetchFeedAsync())
  }
}

let EXPECTED_API_VERSION = 'v1.3';
function apiVersionListener() {
  console.log('ApiVersion auth', store.getState().myUserProfile.apiVersion);
  let apiVersion = store.getState().myUserProfile.apiVersion;

  if (apiVersion === EXPECTED_API_VERSION) {
    console.log('Api version is correct. Current version ' + apiVersion);
  } else if (!!apiVersion && apiVersion !== EXPECTED_API_VERSION) {
    console.log(
      'Api version missmatch, reloading app.' +
        apiVersion +
        '-' +
        EXPECTED_API_VERSION
    );
    const reloadedTs = new Date(localStorage.getItem('forceReloadedAt'));
    if (addHours(reloadedTs, 1) < new Date()) {
      localStorage.setItem('forceReloadedAt', new Date());
      window.location.reload(true);
    } else {
      console.log('Api version was recently reloaded.', apiVersion);
    }
  } else {
    // console.log('Non relevant Api version update', apiVersion)
  }
}

export default store;
