import React from 'react';
import ReactGA from 'react-ga';
import { Button } from 'reactstrap';
import SimpleButtonModal from './SimpleButtonModal';

const ContactUserPage = ({ user, item, reverse, buttonText }) => {
  const mailtoLink = !reverse
    ? 'mailto:' +
      user.email +
      '?subject=' +
      encodeURIComponent('Får jag låna din ' + item.name + '?')
    : 'mailto:' +
      user.email +
      '?subject=' +
      encodeURIComponent('Jag har en ' + item.name + ' du kan låna!');

  // GA doesn't like emails
  const mailtoLinkForGA = 'mailto:' + user._id;

  const facebookLink =
    user.facebook_link || 'https://www.facebook.com/' + user.facebook_id;

  return (
    <>
      <div className="modal-image" />
      <h2 className="text-center mt-3">
        {!!item.currentLoan
          ? 'Hoppas det går bra att låna!'
          : 'Kul att du vill ' + (!reverse ? 'låna' : 'låna ut') + '! 😍'}
      </h2>

      <p className="text-center">
        Du sköter kontakten med {!reverse ? 'utlånaren' : 'lånaren'} så som ni
        vanligtvis kommunicerar, tex via mail eller Facebook.
      </p>
      <p className="text-center">
        {!!item.currentLoan
          ? null
          : 'Om ' +
            (!reverse ? user.short_name : 'du') +
            ' sen markerar ' +
            item.name +
            ' som utlånad får ni båda koll på vad som lånas och du får en påminnelse när det är dags för tillbakalämning. Inga fler bortglömda lån eller jobbiga förseningar! 💪'}
      </p>

      <ReactGA.OutboundLink
        eventLabel={mailtoLinkForGA}
        to={mailtoLink}
        target="_blank"
      >
        <Button
          className="mw-100 float-md-left float-none my-3 mx-1 px-2"
          color={'primary'}
        >
          Kontakta via mail
        </Button>
      </ReactGA.OutboundLink>
      <ReactGA.OutboundLink
        eventLabel={facebookLink}
        to={facebookLink}
        target="_blank"
      >
        <Button
          className="mw-50 float-md-right float-none my-3 mx-1 px-2"
          color={'primary'}
        >
          Kontakta via Facebook
        </Button>
      </ReactGA.OutboundLink>
    </>
  );
};

const ContactUserModal = props => {
  const titleText = !props.reverse
    ? !props.item.currentLoan
      ? 'Fråga om lån'
      : 'Kontakta ' + props.user.short_name
    : 'Låna ut';

  const gaEvent = props.reverse
    ? '/item/requested/contact'
    : '/item/regular/contact';

  return (
    <SimpleButtonModal title={titleText} {...props} gaEventOnShow={gaEvent}>
      <ContactUserPage {...props} />
    </SimpleButtonModal>
  );
};

export default ContactUserModal;
