export const profileToPath = profile =>
  (profile.name || profile._id || 'unknown').replace(' ', '');

export const itemToPath = item => (item._id || 'unknown').replace(' ', '');

let categoryIcons = {
  Accessoarer: '💼',
  'Barn & bäbis': '🍼',
  Boende: '🏠',
  Festligheter: '🎉',
  Fordon: '🚲',
  Friluftsliv: '🏕',
  Hemelektronik: '📺',
  Hobby: '📸',
  Kläder: '👗',
  Köksredskap: '🍴',
  Litteratur: '📚',
  Media: '🎬',
  Möbler: '🛋',
  Resa: '✈️',
  Sport: '⚽️',
  Sällskapsspel: '🎲',
  Tjänster: '👩‍🔧',
  Verktyg: '🛠',
  Övrigt: '👀',
};

export const getCategories = () =>
  Object.entries(categoryIcons).map(([name, icon]) => {
    return { name, icon };
  });

export const getIconFromCategoryName = name => categoryIcons[name] || ' 🎉';

export const getRandomElementFromArray = array =>
  array[Math.floor(Math.random() * array.length)];
