import React from 'react';

const Loading = () => (
  <>
    <div className="align-self-center lds-heart">
      <div />
    </div>
  </>
);

export default Loading;
