import React from 'react';
import Emoji from './Emoji';

const NotFound = () => {
  return (
    <h2>
      Hmmm... du verkar vilse. <Emoji symbol="🧭" />
    </h2>
  );
};

export default NotFound;
