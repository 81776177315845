import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getIconFromCategoryName } from '../helpers';
import { addItemAsync } from '../reducers';
import Emoji from './Emoji';
import Loading from './Loading';

const SHOW_MODAL_IF_RETURN_AFTER_DAYS = 10;
const MAX_ITEMS_TO_SHOW = 5;

const textXOfY = (x, y) => '(' + x + '/' + y + ')';

const SuggestItem = () => {
  const toggle = () => setModalIsOpen(!modalIsOpen);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [resultFlash, setResultFlash] = useState(null);

  const isFirstLogin = useSelector(store => store.myUserProfile.first_login);

  const myLastLogin = useSelector(store => store.myUserProfile.lastLoggedInAt);

  const [itemNumber, setItemNumber] = useState(1);

  const nextItem = () => setItemNumber(itemNumber + 1);

  const initialSuggestionsToShow = (
    useSelector(store => store.myUserProfile.suggestions) || []
  )
    .sort((a, b) => Math.random() - 0.5)
    .slice(0, MAX_ITEMS_TO_SHOW);

  const [suggestionsToShow] = useState(initialSuggestionsToShow);

  const suggestedItem = suggestionsToShow[itemNumber - 1];

  const shouldShowModalInitally =
    !!suggestedItem &&
    !isFirstLogin &&
    addDays(Date.parse(myLastLogin), SHOW_MODAL_IF_RETURN_AFTER_DAYS) <
      new Date();

  const [modalIsOpen, setModalIsOpen] = useState(shouldShowModalInitally);

  const totalModals = suggestionsToShow.length;

  useEffect(() => ReactGA.modalview('/suggestion/' + itemNumber), [itemNumber]);

  const saveItem = item =>
    Promise.resolve(setIsLoading(true))
      .then(() => dispatch(addItemAsync(item)))
      .then(result =>
        setResultFlash(
          <Alert color="success" className="m-3">
            Du har {item.dontSave && 'inte'} lagt till <b>{item.name}</b>
            {getIconFromCategoryName(item.category)}
          </Alert>
        )
      )
      .then(() => (itemNumber === totalModals ? toggle() : nextItem()))
      .catch(err =>
        setResultFlash(
          <Alert color="danger" className="m-3">
            Oops, något gick fel <Emoji symbol="😞" />
          </Alert>
        )
      )
      .finally(() => setIsLoading(false));

  return (
    <Modal isOpen={modalIsOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Kan du låna ut? {textXOfY(itemNumber, totalModals)}
      </ModalHeader>
      <ModalBody>
        <Form>
          {resultFlash}
          {isLoading ? (
            <Loading />
          ) : suggestedItem ? (
            suggestionView(suggestedItem, saveItem)
          ) : (
            noMoreSuggestionsView(toggle)
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

const suggestionView = (suggestedItem, saveItem) => (
  <>
    <p className="text-center emoji-image mb-minus-1">
      {getIconFromCategoryName(suggestedItem.item.category)}
    </p>
    <p className="text-center font-italic font-weight-light text-muted">
      {suggestedItem.item.category}
    </p>
    <p className="text-center">
      Kan du låna ut <b>{suggestedItem.item.name}</b> till dina vänner?
    </p>

    <div className="d-flex justify-content-center">
      <Button
        color="danger"
        className="mx-1"
        onClick={event =>
          saveItem({
            ...suggestedItem.item,
            dontSave: true,
          })
        }
      >
        <Emoji symbol="😔" /> Tyvärr
      </Button>
      <Button
        color="primary"
        className="mx-1"
        onClick={event => saveItem(suggestedItem.item)}
      >
        <Emoji symbol="👍" /> Javisst
      </Button>
    </div>
  </>
);

const noMoreSuggestionsView = toggle => (
  <>
    <p className="text-center emoji-image mb-minus-1">
      <Emoji symbol="🤷‍" />
    </p>
    <p className="text-center">
      Vi har tyvärr inga fler förslag på saker just nu.
    </p>
    <div className="d-flex justify-content-center">
      <Button color="primary" className="mx-1" onClick={toggle}>
        Fortsätt
      </Button>
    </div>
  </>
);

export default SuggestItem;
