export const LANDING = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const BORROW = '/borrow';
export const LEND = '/lend';
export const PROFILE = '/profile';
export const PROFILE_USER = '/profile/:user';
export const TERMS = '/terms';
export const START = '/start';
export const SETTINGS = '/settings';
export const ABOUT = '/about';
export const ITEM = '/item';
export const ITEM_ID = '/item/:id';
