import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import Emoji from './Emoji';
import Loading from './Loading';
import LoginButton from './LoginButton';

const StyledH3 = styled.h3`
  font-size: 1.25rem;
`;

const StyledEmoji = styled.div`
  font-size: 2rem;
`;

const FirstH2 = styled.h2`
  font-size: 2rem;
  margin-top: 18px;
  margin-bottom: 10px;
  color: black;
`;

const SecondH2 = styled.h2`
  font-size: 4.7rem;
  margin-top: 10px;
  margin-bottom: 20px;
  color: black;
`;

const StyledImage = styled.div`
  min-width: 100%;
  height: 550px;
  background-image: url('/lidya-nada-636142-unsplash.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const Login = ({ isLoggingIn }) => (
  <>
    {isLoggingIn ? (
      <Loading />
    ) : (
      <>
        <Container fluid className="landing-container">
          <Row className="mx-0">
            <Col xs="12" md="6" className="landing-container">
              <div className="landing-left px-0 pl-md-4 text-center text-md-left">
                <div className="px-0 pl-lg-5">
                  <h1>Let’s Borrowit</h1>
                  <FirstH2>Köp mindre.</FirstH2>
                  <SecondH2>Låna mer.</SecondH2>
                  <LoginButton />
                </div>
              </div>
            </Col>
            <Col xs="12" md="6" className="landing-container">
              <StyledImage />
            </Col>
          </Row>
        </Container>
        <Container className="text-center mt-5">
          <Row style={{ backgroundColor: '#ffffff' }}>
            <Col xs="12" md="4" className="mt-4">
              <StyledEmoji>
                <Emoji symbol="🤗" />
              </StyledEmoji>
              <StyledH3>Låna saker från dina vänner</StyledH3>
              <p>
                Sök bland dina vänners prylar efter sånt du vill låna, eller
                lägg upp att du söker något specifikt. Kontakta utlånaren när du
                hittat nåt du vill låna!
              </p>
            </Col>
            <Col xs="12" md="4" className="mt-4">
              <StyledEmoji>
                <Emoji symbol="💁‍" />
              </StyledEmoji>
              <StyledH3>Låna ut saker till dina vänner</StyledH3>
              <p>
                Säsongsutrustning, verktyg, böcker, hobbyprylar eller annat som
                mest fyller upp hyllorna. Låna ut och gör dina vänner glada!
              </p>
            </Col>
            <Col xs="12" md="4" className="mt-4">
              <StyledEmoji>
                <Emoji symbol="😍" />
              </StyledEmoji>
              <StyledH3>Köp mindre, låna mer!</StyledH3>
              <p>
                Spara på pengarna, samvetet och miljön - allt som ligger ute på
                Let’s Borrowit bland dina Facebookvänner kan du låna!
              </p>
            </Col>
          </Row>
          <Row className="my-5 justify-content-center">
            <Col xs="12" sm="12" md="4" lg="3" className="py-3 px-2">
              <StyledH3>
                Mer pengar <Emoji symbol="💰" />
              </StyledH3>
            </Col>
            <Col xs="12" sm="12" md="4" lg="3" className="py-3 px-2">
              <StyledH3>
                Mer plats <Emoji symbol="🧘‍" />
              </StyledH3>
            </Col>
            <Col xs="12" sm="12" md="4" lg="3" className="py-3 px-2">
              <StyledH3>
                Mindre konsumtion <Emoji symbol="🌱" />
              </StyledH3>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoginButton />
            </Col>
          </Row>
        </Container>
      </>
    )}
  </>
);

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggingIn: state.auth.isLoggingIn,
  };
};

Login.propTypes = {
  isLoggingIn: PropTypes.bool,
};

export default connect(mapStateToProps)(Login);
