import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Login from './Login';

const Home = ({ isLoggedIn }) => <>{isLoggedIn ? <></> : <Login />}</>;

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

Home.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default connect(mapStateToProps)(Home);
