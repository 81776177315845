import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactGA from 'react-ga';

const SimpleButtonModal = ({
  buttonText,
  className,
  title,
  children,
  gaEventOnShow,
  outline,
  color,
}) => {
  const [isVisible, setVisibility] = useState(false);

  const showModal = () => {
    if (!!gaEventOnShow) ReactGA.modalview(gaEventOnShow);
    setVisibility(true);
  };

  const hideModal = () => setVisibility(false);

  return (
    <>
      <Button
        className={className || ''}
        onClick={showModal}
        color={color || 'link'}
      >
        {buttonText}
      </Button>
      <Modal isOpen={isVisible} toggle={hideModal}>
        <ModalHeader toggle={hideModal}>{title}</ModalHeader>
        <ModalBody>
          {React.cloneElement(children, {
            hideModal: hideModal,
          })}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SimpleButtonModal;
