import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import AddItemModal from './AddItemModal';
import Emoji from './Emoji';
import ItemList from './ItemList';

const datefns = require('date-fns');

const ExploreRequests = props => (
  <>
    <div className="text-center mt-3">
      <h2>Saker som sökes</h2>
      <AddItemModal requested />
    </div>

    <div className="float-left mt-5" style={{ width: '100%' }}>
      {props.allItems.length > 0 ? (
        <ItemList items={props.allItems} />
      ) : (
        <p className="text-center">
          Ingen av dina vänner vill låna något just nu <Emoji symbol="🤷‍♂️" />
        </p>
      )}
    </div>
  </>
);

const mapStateToProps = (state, ownProps) => ({
  allItems: Object.values(state.items.itemMap)
    .filter(item => item.requested)
    .filter(
      item =>
        !!item.showUntil &&
        datefns.isBefore(Date.now(), Date.parse(item.showUntil))
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  isLoading: state.items.isLoading,
});

ExploreRequests.propTypes = {
  requesteduseritems: PropTypes.any,
};

export default connect(mapStateToProps)(ExploreRequests);
