import { addMonths } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { addItemAsync, registerLoanAsync } from '../reducers';
import DatePicker from './DatePicker';
import Emoji from './Emoji';
import FriendList from './FriendList';
import ImageUploader from './ImageUploader';
import Loading from './Loading';
import SimpleButtonModal from './SimpleButtonModal';

const QuickLoanPage = ({ hideModal }) => {
  const initialItemState = {
    name: '',
    category: '',
    description: '',
    give: false,
    showUntil: null,
    requested: false,
  };

  const [isLoading, setIsLoading] = useState(false);

  // Errors will initially not be shown. If trying to submit they will show up.
  const [showErrors, setShowErrors] = useState(false);

  // When the request has been sent these messages will be shown on success/error
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();

  // The object that will be sent to the api.
  const [item, setItem] = useState(initialItemState);

  const [isOutloan, setIsOutloan] = useState(true);
  const [friend, setFriend] = useState();
  const [untilDate, setUntilDate] = useState();

  const myUserId = useSelector(store => store.myUserProfile._id);
  // const suggestion = useSelector(
  //   store =>
  //     Object.values(store.items.itemMap)
  //       .filter(item => item.requested)
  //       .filter(item => !!item.name)
  //       .map(item => {
  //         return {
  //           itemName: item.name || 'något',
  //           userName: store.items.userMap[item.owner].name || 'Någon',
  //         };
  //       })[0]
  // );

  const dispatch = useDispatch();

  const validateInput = field => {
    const validationRules = [
      {
        field: 'name',
        text: 'Du har inte skrivit något 🧐',
        condition: !item.name || item.name === '',
      },
      {
        field: 'name',
        text: 'Det där var en väldigt lång titel.. 🧐',
        condition: item.name && item.name.length > 100,
      },
      {
        field: 'untilDate',
        text: 'Datumet har redan passerat. 🙈',
        condition: untilDate < Date.now(),
      },
      {
        field: 'friend',
        text: 'Du måste välja en vän. 🙈',
        condition: friend === undefined || friend._id === undefined,
      },
    ];
    return validationRules
      .filter(rules => !field || rules.field === field)
      .filter(rule => rule.condition)
      .map(rule => rule.text);
  };

  const onSubmit = event => {
    setSuccessMessage(null);
    setFailedMessage(null);

    event.preventDefault();
    if (validateInput().length > 0) {
      console.log('Validation error', validateInput());
      return setShowErrors(true);
    }

    setIsLoading(true);

    return dispatch(
      addItemAsync({
        ...item,
        owner: !isOutloan ? friend._id : myUserId,
      })
    )
      .then(justAddedItem =>
        dispatch(
          registerLoanAsync({
            itemId: justAddedItem._id,
            ownerId: !isOutloan ? friend._id : myUserId,
            borrowerId: !!isOutloan ? friend._id : myUserId,
            untilDate: untilDate,
          })
        )
      )
      .then(() =>
        setSuccessMessage(
          <>
            <b>{item.name}</b> är tillagd!
            <Emoji symbol="🙃" />
          </>
        )
      )
      .then(() => setItem(initialItemState))
      .catch(err => {
        console.log('Error when adding quickloan', err);
        setFailedMessage(
          <>
            Oops, något gick fel <Emoji symbol="😞" />
          </>
        );
      })
      .finally(() => setIsLoading(false))
      .finally(() => setShowErrors(false));
  };

  const titlePlaceholderText = 'Tex: Fotbollsskor';
  const descriptionPlaceholderText = 'Tex: Adidas. Storlek 43.';

  const handleChange = event => {
    const updatedItem = { ...item };

    if (!!event.target && event.target.id === 'giveCheckbox') {
      updatedItem[event.target.name] = event.target.checked;
    } else if (event.name === 'showUntil') {
      updatedItem[event.name] = new Date(event.value);
    } else if (event.name === 'image_id') {
      updatedItem['image_id'] = event.value.cloudinary_id;
      updatedItem['image'] = { ...event.value };
    } else {
      updatedItem[event.target.name] = event.target.value;
    }

    setItem(updatedItem);
  };

  if (isLoading) return <Loading />;

  return (
    <>
      {!!successMessage ? (
        <Alert color="success" className="m-3">
          {successMessage}
        </Alert>
      ) : null}

      {!!failedMessage ? (
        <Alert color="danger" className="m-3">
          {failedMessage} <Emoji symbol="😞" />
        </Alert>
      ) : null}
      <Form onSubmit={onSubmit}>
        <ButtonToolbar>
          <ButtonGroup>
            {Object.entries({
              'Jag lånar av...': false,
              'Jag lånar ut till...  ': true,
            }).map(text => {
              return (
                <Button
                  key={text[0]}
                  outline
                  color="primary"
                  onClick={() => setIsOutloan(text[1])}
                  active={isOutloan === text[1]}
                >
                  {text[0]}
                </Button>
              );
            })}
          </ButtonGroup>
        </ButtonToolbar>
        <br />
        <FormGroup>
          <FriendList onClick={friend => setFriend(friend)} />
          {showErrors &&
            validateInput('friend').map((text, index) => (
              <div class="invalid-feedback d-block" key={index}>
                {text}
              </div>
            ))}
        </FormGroup>

        <FormGroup>
          <Label for="exampleText">Titel</Label>
          <Input
            type="text"
            //   ref="itemname"
            name="name"
            value={item.name}
            invalid={showErrors && validateInput('name').length > 0}
            placeholder={titlePlaceholderText}
            onChange={handleChange}
          />
          {validateInput('name').map((text, index) => (
            <FormFeedback key={index}>{text}</FormFeedback>
          ))}
        </FormGroup>

        <FormGroup>
          <Label for="exampleText">Beskrivning</Label>
          <Input
            type="textarea"
            name="description"
            value={item.description}
            placeholder={descriptionPlaceholderText}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup className="my-3">
          <ImageUploader
            imageId={item.image_id}
            onUploadComplete={(imageId, imageWidth, imageHeight, imageType) =>
              handleChange({
                name: 'image_id',
                value: {
                  cloudinary_id: imageId,
                  width: imageWidth,
                  height: imageHeight,
                  type: imageType,
                },
              })
            }
          />
        </FormGroup>

        <FormGroup>
          <Label for="exampleText">
            Hur länge? Vi skickar en påminnelse så att ni inte glömmer.
          </Label>
          <DatePicker
            invalid={showErrors && validateInput('untilDate').length > 0}
            initialDate={addMonths(new Date(), 1)}
            onChange={setUntilDate}
          />
          {validateInput('untilDate').map((text, index) => (
            <FormFeedback key={index}>{text}</FormFeedback>
          ))}
        </FormGroup>

        <Button color="primary">
          <Emoji symbol="☝️" />
          Lägg till!
        </Button>
      </Form>
    </>
  );
};

const QuickLoanModal = props => {
  return (
    <SimpleButtonModal
      buttonText={
        <h4>
          <Emoji symbol="⚡️" />
        </h4>
      }
      title="Snabbregistrera lån!"
      {...props}
    >
      <QuickLoanPage {...props} />
    </SimpleButtonModal>
  );
};

export default QuickLoanModal;
