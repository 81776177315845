export const SET_ITEM_FILTERING = 'SET_ITEM_FILTERING';

export const ADD_NEW_ITEM_REQUEST = 'ADD_NEW_ITEM_REQUEST';
export const ADD_NEW_ITEM = 'ADD_NEW_ITEM';

export function setItemFiltering(filter) {
  return { type: SET_ITEM_FILTERING, filter };
}

export function addNewItemRequest(item) {
  return { type: ADD_NEW_ITEM_REQUEST, item };
}

export function addNewItem(item) {
  return { type: ADD_NEW_ITEM, item };
}
