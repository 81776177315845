import React from 'react';
import PropTypes from 'prop-types';

/**
 * TODO: add profile.name to all profile pictures on the site
 */
const ProfilePicture = ({ profile, imageId, radius, className }) => (
  <div className={'float-left ' + className}>
    <img
      alt={(profile || {}).name || 'Profile picture'}
      src={`https://res.cloudinary.com/${
        process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
      }/image/upload/c_thumb,g_face,h_${radius || 40},r_max,w_${radius ||
        40}/v1523773349/${imageId}.png`}
    />
  </div>

  /*

  This is very slow on loading. But preferable we would use this when it is fixed:
    <div className={'float-left ' + className}>
    <Image cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
      publicId= {imageId + '.png'}>
      <Transformation
        width={radius || 40}
        height={radius || 40}
        crop='thumb'
        gravity='face'
        radius='max'
      />
    </Image>
  </div>

  */
);

ProfilePicture.propTypes = {
  profile: PropTypes.object,
  imageId: PropTypes.string,
  radius: PropTypes.number,
  className: PropTypes.string,
};

export default ProfilePicture;
