import React from 'react';
import { connect } from 'react-redux';
import {
  // Button,
  Modal,
  // ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Item from './Item';

/**
 * To use ItemModal, use
 * <Link
 *  to={{
 *    pathname: `${routes.ITEM}/${item._id}`,
 *    state: { modal: true } // this is the trick!
 *   }}
 *  >
 *   Link to Modal
 *  </Link>
 */
class ItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };

    this.toggle = this.toggle.bind(this);
    this.back = this.back.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  back(e) {
    e.stopPropagation();
    this.props.history.goBack();
  }

  render() {
    return (
      <>
        {!this.props.match ||
        !this.props.match.params ||
        !this.props.match.params.id ||
        !this.props.item ? null : (
          <div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.back}
              className={this.props.className}
            >
              <ModalHeader toggle={this.back}>
                {this.props.item.name}
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    width: '300px',
                    margin: 'auto',
                  }}
                >
                  <Item
                    key={this.props.item._id}
                    item={this.props.item}
                    user={this.props.owner_decoration}
                    showTown
                    showExtendedActions
                  />
                </div>
              </ModalBody>
              {/* <ModalFooter>
                <Button color='primary' onClick={this.back}>Do Something</Button>{' '}
                <Button color='secondary' onClick={this.back}>Cancel</Button>
              </ModalFooter> */}
            </Modal>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: Object.values(state.items.itemMap).find(
      o => o._id === ownProps.match.params.id
    ),
  };
};

export default connect(mapStateToProps)(ItemModal);
