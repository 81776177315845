import React from 'react';
import PropTypes from 'prop-types';

import Emoji from './Emoji';
import ShareButton from './ShareButton';

const NoItems = ({ noOfFriends, noOfAllItems, noOfFriendItems }) => (
  <>
    {noOfFriends < 1 ? (
      <div>
        <h3>
          Åh, nej <Emoji symbol="😢" />
        </h3>
        <p>{`Du har inga vänner på Let's Borrowit. Bjud in dina vänner för att börja låna!`}</p>
        <ShareButton />
        <p className="mt-4">
          Lägg upp saker du kan tänka dig att låna ut till dina vänner.
        </p>
      </div>
    ) : noOfAllItems < 1 ? (
      <div>
        <h3>
          Åh, nej <Emoji symbol="😢" />
        </h3>
        <p>
          Varken du eller någon av dina vänner har saker att låna ut. Bli den
          första att lägga till en sak!
        </p>
      </div>
    ) : noOfFriendItems < 1 ? (
      <div>
        <p>
          Ingen av dina vänner har saker att låna ut <Emoji symbol="😢" />
        </p>
        <ShareButton />
      </div>
    ) : null}
  </>
);

NoItems.propTypes = {
  noOfFriends: PropTypes.number,
  noOfAllItems: PropTypes.number,
  noOfFriendItems: PropTypes.number,
};

export default NoItems;
