import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import StackGrid from 'react-stack-grid';
import Item from './Item';
import Loading from './Loading';

const ItemList = ({ items, showTown }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Loading />}
      <StackGrid
        monitorImagesLoaded={false}
        duration={0}
        gutterWidth={20}
        gutterHeight={20}
        columnWidth={300}
        onLayout={() => setLoading(false)}
      >
        {items.map(item => (
          <Fragment key={item._id}>
            <Item
              key={item._id + (item.image ? '-' + item.image._id : '')}
              item={item}
              user={item.decorated_owner}
              showTown={showTown}
            />
          </Fragment>
        ))}
      </StackGrid>
    </>
  );
};

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  showTown: PropTypes.bool,
};

export default ItemList;
